import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
// import { debounceTime, distinctUntilChanged, from, Subject, switchMap, takeUntil, tap, toArray } from "rxjs";
import { UsersService } from 'app/services/users.service';
import { map } from 'rxjs';

@Component({
  selector: 'tm-user-selection',
  templateUrl: './user-selection.component.html',
  styleUrls: ['./user-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule]
})
export class UserSelectionComponent implements OnInit, OnDestroy {
  users$ = this.usersService.get().pipe(
    map(users => users.sort((u1, u2) => u1.displayName.localeCompare(u2.displayName)))
  );
  // private readonly destroy$ = new Subject<void>();
  readonly usersFC = new FormControl<Array<string>>([]);
  @Output() private selectionChange = new EventEmitter<string[]>();
  private prevSelectedUsersIds: Array<string> = [];

  constructor(private readonly usersService: UsersService) { }

  isDisabled(userId: string): boolean {
    const usersSelectedIds = this.usersFC.value;
    if (usersSelectedIds.length >= 10) {
      return usersSelectedIds.findIndex(id => id === userId) > -1 ? false : true;
    } else return false;
  }

  close(): void {
    const currSelectedUsersIds = this.usersFC.value;
    if (this.prevSelectedUsersIds.join() !== currSelectedUsersIds.join()) {
      this.prevSelectedUsersIds = currSelectedUsersIds;
      this.selectionChange.emit(currSelectedUsersIds);
    }
  }

  ngOnInit(): void {
    this.selectionChange.emit([]);
    // this.usersFC.valueChanges.pipe(
    //   debounceTime(250),
    //   distinctUntilChanged((prev: string[], curr: string[]) => prev.join() === curr.join()),
    //   takeUntil(this.destroy$),
    // ).subscribe((usersIds) => {
    //   this.selectionChange.emit(usersIds);
    // });
  }

  ngOnDestroy(): void {
    // this.destroy$.next();
    // this.destroy$.complete();
  }
}