import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { AppConfig, APP_CONFIG } from 'app/app-config.module';
import { Invitation, InvitationList } from 'app/models/invite/invitation';
import { OrganizationService } from 'app/services/organization.service';
import { Observable, map, tap } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class InviteService {

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly organizationService: OrganizationService
  ) { }

  sendInvite(emails: { emails: Array<any> | string }, params: HttpParams): Observable<Array<{ inviteLink: string; statusEmail: boolean, statusMessage: null }>> {
    const url = `${this.appConfig.oldBaseUrl}/invite`;
    return this.httpClient.post(url, emails, { params }) as Observable<Array<{
      inviteLink: string;
      statusEmail: boolean;
      statusMessage: null
    }>>;
  }

  sendInviteToTeam(data): Observable<any> {
    const url = `${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/invite`;
    return this.httpClient.post(url, data);
  }

  invitationsList(): Observable<Array<Invitation>> {
    const url = `${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/invitations`;
    return this.httpClient.get<InvitationList>(url).pipe(
      map((invitationList) => invitationList.list)
    );
  }
}

