import { ChangeDetectionStrategy, Component } from '@angular/core';
// import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { LocalStorageService } from 'app/services/local-storage.service';

@Component({
  selector: 'tm-login-failed',
  templateUrl: './login-failed.component.html',
  styleUrls: ['./login-failed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFailedComponent {
  constructor(
    private readonly auth0Service: AuthService,
    // private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
  ) { }

  tryAgain() {
    this.auth0Service.loginWithRedirect();
  }

  logout() {
    this.localStorageService.reset();
    this.auth0Service.logout();
  }
}