<div class="flex flex-col">
  <div class="flex items-center justify-between mb-4">
    <div class="text-2xl leading-tight">Add Members</div>
    <button mat-icon-button matDialogClose><mat-icon svgIcon="mat_solid:close"></mat-icon></button>
  </div>
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Invite via email">
      <div class="flex flex-col gap-4" [formGroup]="inviteForm">
        <mat-form-field [subscriptSizing]="'dynamic'" class="w-full fuse-mat-dense">
          <mat-label>Emails</mat-label>
          <mat-chip-grid #chipGrid aria-label="Enter email" formControlName="emails">
            <mat-chip-row *ngFor="let email of emailsFC.value" (removed)="removeEmail(email)" [value]="email">
              {{email}}
              <mat-icon class="icon-size-5" matChipRemove>cancel</mat-icon>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="Add email..." [matChipInputFor]="chipGrid"
            (matChipInputTokenEnd)="addEmail($event)" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"/>
          <mat-error *ngIf="emailsFC.hasError('required')">Email is required</mat-error>
          <mat-error *ngIf="emailsFC.hasError('isNotValid')">Is not a valid email</mat-error>
        </mat-form-field>
        <mat-form-field [subscriptSizing]="'dynamic'" class="w-full fuse-mat-dense">
          <mat-label>Role</mat-label>
          <mat-select formControlName="role">
            <mat-option value="Member">Member</mat-option>
            <mat-option value="Manager">Manager</mat-option>
          </mat-select>
          <mat-error>Role is requred!</mat-error>
        </mat-form-field>
        <tm-autocopmlete-select-projects></tm-autocopmlete-select-projects>
        <!-- <mat-form-field [subscriptSizing]="'dynamic'" class="w-full fuse-mat-dense">
          <mat-label>Projects</mat-label>
          <mat-chip-grid #chipGridProjects>
            <mat-chip-row *ngFor="let project of projectsFC.value" (removed)="removeProject(project.id)">
              {{project.name}}
              <mat-icon class="icon-size-5" matChipRemove>cancel</mat-icon>
            </mat-chip-row>
            <input [placeholder]="'Search...'" [matChipInputFor]="chipGridProjects" [matAutocomplete]="autoProjects"
              [formControl]="projectsSearchFC" #projectSearchInput>
            <mat-autocomplete autoActiveFirstOption #autoProjects="matAutocomplete"
              (optionSelected)="projectSelected($event)">
              <mat-option *ngFor="let project of projects$ | async" [value]="project">
                {{project.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-chip-grid>
        </mat-form-field> -->
        <!-- <mat-form-field [subscriptSizing]="'dynamic'" class="w-full fuse-mat-dense">
          <mat-label>Teams</mat-label>
          <mat-chip-grid #chipGridTeams>
            <mat-chip-row *ngFor="let team of teamsFC.value" (removed)="removeTeam(team.id)">
              {{team.name}}
              <mat-icon class="icon-size-5" matChipRemove>cancel</mat-icon>
            </mat-chip-row>
            <input [placeholder]="'Search...'" [matChipInputFor]="chipGridTeams" [matAutocomplete]="autoTeams"
              [formControl]="teamsSearchFC" #teamSearchInput>
            <mat-autocomplete autoActiveFirstOption #autoTeams="matAutocomplete"
              (optionSelected)="teamSelected($event)">
              <mat-option *ngFor="let team of teams$ | async" [value]="team">
                {{team.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-chip-grid>
        </mat-form-field> -->
      </div>
      <div class="flex justify-end mt-4 gap-2">
        <button mat-stroked-button matDialogClose>Cancel</button>
        <button mat-raised-button color="primary" [disabled]="inviteForm.invalid || (submitting$ | async)"
          (click)="submit()">
          <span *ngIf="!(submitting$ | async); else spinner">Send</span>
          <ng-template #spinner>
            <mat-spinner diameter="25"></mat-spinner>
          </ng-template>
        </button>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>