import { UserOrganizationRole } from "../organization/organization"
import { Project } from "../project/project"


export interface User {
  id: string,
  displayName: string | null,
  firstName?: string,
  lastName?: string,
  role: UserOrganizationRole | UserGlobalRole,
  projects?: Array<Project>,
  jobRole?: number,
  onboarding?: {
    completedSteps: [],
    completed: boolean,
    skipped: boolean
  },
  isOnline: boolean
}

export interface OrganizationUser {
  id: string,
  displayName: string,
  role: UserOrganizationRole | UserGlobalRole,
  jobRole?: number
}

export enum UserGlobalRole {
  SuperAdmin = 'SuperAdmin'
}


export interface CurrentUser {
  id: string,
  displayName: string,
  firstName: string,
  lastName: string,
  roles?: [UserGlobalRole],
}

export interface TeamUser {
  id: string,
  displayName: string,
  roles?: [UserGlobalRole],
  jobRole?: number,
  role: UserOrganizationRole,
  projects?: Array<Project>,
  isOnline?: boolean
}