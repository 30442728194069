<mat-form-field class="fuse-mat-dense w-full" [subscriptSizing]="'dynamic'">
  <mat-label>Time Zone</mat-label>
  <mat-icon class="icon-size-5" [svgIcon]="'mat_outline:location_searching'" matPrefix></mat-icon>
  <mat-select placeholder="Select Time Zone" formControlName="timeZoneId">
    <mat-option>
      <ngx-mat-select-search [formControl]="timeZonesFilterCtrl" placeholderLabel="Find Country..." noEntriesFoundLabel="'no matching time zone found'">
        werf
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let timezone of timeZoneListFilter$ | async" [value]="timezone">{{timezone}}</mat-option>
  </mat-select>
  <mat-error>Time Zone field is required</mat-error>
</mat-form-field>