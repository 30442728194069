import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/core/material/material.module';
import { OrganizationCategory } from 'app/models/category/category';
import { OrganizationService } from 'app/services/organization.service';
import { NgForAugmentedDirective } from 'app/shared/directives/ng-for-augmented.directive';
import { filter, takeUntil, Subject, startWith, switchMap, merge, debounceTime, distinctUntilChanged } from 'rxjs';

interface CategoryForm {
  type: FormControl<'job' | 'ticket'>,
  parentId: FormControl<string>,
  name: FormControl<string>,
}

@Component({
  selector: '',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MaterialModule, FormsModule, ReactiveFormsModule, CommonModule, NgForAugmentedDirective],
})
export class EditCategoryComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly refreshCategoriesList$ = new Subject<void>();
  private readonly updateCategory$ = new Subject<OrganizationCategory>();
  private readonly loading$ = new Subject<null>();

  private organizationCategories = [];
  rootCategories = [];
  filteredCategories = [];

  readonly categoryForm = this.fb.group<CategoryForm>({
    type: new FormControl('job', [Validators.required]),
    parentId: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
  });


  constructor(
    private readonly organizationService: OrganizationService,
    private readonly fb: FormBuilder,
    private readonly chr: ChangeDetectorRef
  ) { }

  get parentIdFC(): FormControl<string> {
    return this.categoryForm.get('parentId') as FormControl<string>;
  }

  get typeFC(): FormControl<'job' | 'ticket'> {
    return this.categoryForm.get('type') as FormControl<'job' | 'ticket'>;
  }

  get newCategoryFC(): FormControl<string> {
    return this.categoryForm.get('name') as FormControl<string>;
  }

  get isCreateNewCategory(): boolean {
    return this.parentIdFC.value !== '' && this.newCategoryFC.value.trim() !== ''
  }

  addCategory(): void {
    if (this.categoryForm.valid) {
      const type = this.typeFC.value
      const data = {
        parentId: this.categoryForm.get('parentId').value,
        name: this.categoryForm.get('name').value,
        organizationId: this.organizationService.organizationSelectedId
      };
      this.organizationService.addCategory(data, type).subscribe(() => {
        this.refreshCategoriesList$.next();
        this.newCategoryFC.reset('');
      });
    }
  }

  deleteCategory(id: string): void {
    const type = this.typeFC.value;
    this.organizationService.deleteCategory(id, type).subscribe(() => {
      this.refreshCategoriesList$.next();
    });
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  ngOnInit(): void {
    this.updateCategory$.pipe(
      debounceTime(300),
      distinctUntilChanged((category, category2) => category.name === category2.name),
      filter(category => category.name.trim() !== ''),
      takeUntil(this.destroy$),
      switchMap((data) => {
        const type = this.typeFC.value;
        return this.organizationService.updateCategory(data, type)
      })
    ).subscribe(() => {
      this.refreshCategoriesList$.next();
      this.newCategoryFC.reset('');
    })

    this.typeFC.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.parentIdFC.setValue('');
    })

    merge(this.refreshCategoriesList$, this.typeFC.valueChanges).pipe(
      startWith(this.typeFC.value),
      switchMap(() => this.organizationService.categoriesList(this.typeFC.value)),
      takeUntil(this.destroy$),
    ).subscribe(categories => {
      this.organizationCategories = categories.filter(category => category?.parentId);
      this.rootCategories = categories.filter(category => !category?.parentId);
      const parentId = this.parentIdFC.value;
      this.parentIdFC.setValue(parentId);
    });

    merge(this.parentIdFC.valueChanges, this.newCategoryFC.valueChanges).pipe(
      takeUntil(this.destroy$)
    ).subscribe((() => {
      const name = this.newCategoryFC.value;
      const parentId = this.parentIdFC.value;
      this.filteredCategories = this.organizationCategories
        .filter(category => category.parentId === parentId)
        .filter(category => category.name.includes(name || ''));
      this.chr.markForCheck();
    }));

    // this.newCategoryFC.valueChanges.pipe(
    //   takeUntil(this.destroy$)
    // ).subscribe((name) => {
    //   const pare
    //   this.filteredCategories = this.filteredCategories.filter(category => category.name.includes(name || ''));
    // });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
