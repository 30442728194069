import { DATE_PIPE_DEFAULT_OPTIONS, DOCUMENT, registerLocaleData } from '@angular/common';
import { Injectable, InjectionToken, LOCALE_ID, NgModule, Optional, SkipSelf, inject, } from '@angular/core';
import { LuxonDateAdapter, MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { IconsModule } from 'app/core/icons/icons.module';
// const CountryLanguage = require('@ladjs/country-language');

import en from '@angular/common/locales/en-GB';
import { Router } from '@angular/router';
import { LocalStorageService } from 'app/services/local-storage.service';
import { S_LOCALE } from 'app/services/selected-locale.service';
import { MAT_SELECTSEARCH_DEFAULT_OPTIONS, MatSelectSearchOptions } from 'ngx-mat-select-search';
// import ru from '@angular/common/locales/ru';
registerLocaleData(en);
// registerLocaleData(ru);

export const WINDOW = new InjectionToken<Window>(
  'An abstraction over global window object',
  {
    factory: () => inject(DOCUMENT).defaultView!
  },
);

export const NAVIGATOR = new InjectionToken<Navigator>(
  'An abstraction over window.navigator object',
  {
    factory: () => inject(WINDOW).navigator,
  },
);

// const countryLanguage = (code: string) => CountryLanguage.getLanguage(code, function (err, language) {
//   if (err) {
//   } else {
//     return language.countries;
//   }
// });

@Injectable()
export class CustomDateAdapter extends LuxonDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1;
  }
}

@NgModule({
  imports: [
    IconsModule,
    // TranslocoCoreModule,
    MatLuxonDateModule
  ],
  // exports: [TranslocoModule],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (navigator, sLocaleService, localStorageService) => {
        const sLocale = sLocaleService;
        const locale = sLocale || navigator?.language || 'en-GB';
        localStorageService.setItem('locale', locale);
        return locale;
      },
      deps: [NAVIGATOR, S_LOCALE, LocalStorageService]
    },
    { provide: LOCALE_ID, useValue: 'en-GB' },

    // { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useFactory: () => {
        return { dateFormat: 'mediumDate' }
      }
    },
    {
      provide: MAT_SELECTSEARCH_DEFAULT_OPTIONS,
      useValue: <MatSelectSearchOptions>{
        closeIcon: 'close',
        recreateValuesArray: false
        // clearSearchInput: true,

      }
    }
  ]
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(
    @Optional() @SkipSelf() parentModule?: CoreModule
  ) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }
  }
}
