import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig, APP_CONFIG } from 'app/app-config.module';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'tm-invite-confirm',
  templateUrl: './invite-confirm.component.html',
  styleUrls: ['./invite-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteConfirmComponent implements OnInit {
  readonly destroy$ = new Subject<void>();
  isLoad = true;
  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private readonly chr: ChangeDetectorRef,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) { }

  onReload(): void {
    window.location.reload();
  }

  ngOnInit(): void {
    const projId = this.activatedRoute.snapshot.queryParams['projId'];
    const inviteToken = this.activatedRoute.snapshot.queryParams['invite_token'];
    const isOrg = this.activatedRoute.snapshot.queryParams['isOrg'] || false;
    const url = `${this.appConfig.oldBaseUrl}/api/invite`;
    const params = new HttpParams()
      .append('projId', projId)
      .append('invite_token', inviteToken)
      .append('isOrg', isOrg);

    this.http.get(url, { params }).pipe(
      takeUntil(this.destroy$)).subscribe({
        next: () => {
          this.snackBar.open('Confirmed!', '', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          this.router.navigateByUrl('onboarding');
        },
        error: (err) => {
          console.error(err);
          this.isLoad = false;
          this.chr.markForCheck();
        }
      });
  }
}