<div class="relative rounded-full">
  <div class="flex flex-col rounded-full border border-primary-700 z-10 bg-card pl-[3px] pr-2 py-0.5 w-full">
    <div class="flex items-center gap-1">
      <ng-container *ngIf="(state$ | async); else spinnerTmpl">
        <div class="min-w-6">
          <ng-container [ngTemplateOutlet]="(isPlay$ | async) ? stopBtnTmpl : playBtnTmpl"></ng-container>
        </div>
        <div class="flex-auto min-w-0" *ngIf="selectedObject$ | async as selectedObject">
          <div class="flex gap-1 items-center flex-auto leading-5">
            <div
              class="cursor-pointer hover:bg-slate-200 dark:hover:bg-slate-600 rounded-md pl-1.5 flex items-center min-w-0"
              [matMenuTriggerFor]="menu" [matTooltip]="selectedObject?.title" #menuTr="matMenuTrigger">
              <div class="truncate text-sm md:text-md">{{selectedObject?.title}}</div>
              <mat-icon svgIcon="more_horiz" class="dark:text-white icon-size-5"></mat-icon>
            </div>
            <div *ngIf="isPlayValue" class="font-bold text-sm min-w-14 md:text-[14px] leading-4 text-end md:min-w-18">
              {{durationActive$ | async | secondsToTimePipe}}
            </div>
            <!-- <mat-icon *ngIf="!isPip" class="icon-size-4 cursor-pointer ml-auto dark:text-white"
              [svgIcon]="'heroicons_outline:arrows-expand'" (click)="resize()"></mat-icon> -->
              <svg *ngIf="!isPip" height="16px" width="16px" viewBox="0 0 24 24" class="hidden sm:block cursor-pointer ml-auto fill-slate-600 dark:fill-white" (click)="resize()"><path d="M21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H3V5h18v14zm-10-7h9v6h-9z"></path></svg>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="text-xs text-warn-500 pl-2 sm:pl-3" *ngIf="errors$ | async as errors">
  {{errors}}
</div>

<ng-template #playBtnTmpl>
  <button class="w-6 h-6 rounded-full flex justify-center items-center border-2 border-primary-700 shadow-md" mat-ripple
    (click)="play()">
    <mat-icon class="icon-size-4 text-primary-700" svgIcon="mat_solid:play_arrow"
      [matTooltip]="'Start tracking'"></mat-icon>
  </button>
</ng-template>

<ng-template #stopBtnTmpl>
  <button class="w-6 h-6 rounded-full flex justify-center items-center border-2 border-primary-700 shadow-md" mat-ripple
    (click)="stop()">
    <mat-icon class="icon-size-4 text-primary-700" svgIcon="mat_solid:stop" [matTooltip]="'Stop tracking'"></mat-icon>
  </button>
</ng-template>

<ng-template #spinnerTmpl>
  <div class="flex flex-auto justify-center">
    <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
    <mat-spinner [diameter]="20"></mat-spinner>
  </div>
</ng-template>

<mat-menu #menu="matMenu" class="min-w-40">
  <button *ngIf="projectsList.length > 0" mat-menu-item [matMenuTriggerFor]="projectsMenu">Projects</button>
  <button *ngIf="ticketsList.length > 0" mat-menu-item [matMenuTriggerFor]="ticketsMenu">Tasks</button>
  <!-- <mat-divider></mat-divider> -->
  <button mat-menu-item (click)="createTicket()">
    <mat-icon svgIcon="heroicons_outline:plus"></mat-icon>
    <span>Add Task</span>
  </button>
  <button mat-menu-item (click)="sessionNewAdd()">
    <mat-icon svgIcon="heroicons_outline:plus"></mat-icon>
    <span>Log Manual Time</span>
  </button>
</mat-menu>

<mat-menu #projectsMenu="matMenu" class="max-h-120">
  <button mat-menu-item *ngFor="let project of projectsList" (click)="changeObject(project)"
    [disabled]="project.id === (selectedObject$ | async)?.id">{{project.title}}</button>
</mat-menu>

<mat-menu #ticketsMenu="matMenu" class="max-h-120">
  <button mat-menu-item *ngFor="let ticket of ticketsList" (click)="changeObject(ticket)"
    [disabled]="ticket.id === (selectedObject$ | async)?.id">{{ticket.title}}</button>
</mat-menu>


<div class="h-0 w-0 overflow-hidden" id="widget22-container">
  <div id="widget22" class="min-w-80 py-1.5 px-2 text-white leading-5 flex gap-2 items-center">
    <div class="min-w-8">
      <ng-container [ngTemplateOutlet]="(isPlay$ | async) ? stopBtnTmpl2 : playBtnTmpl2"></ng-container>
    </div>
    <div class="flex-auto min-w-0">
      <div class="flex-auto min-w-0 flex items-center justify-between"
        *ngIf="selectedObject$ | async as selectedObject">
        <div class="truncate text-lg">{{selectedObject?.title}}<span *ngIf="isPlayValue">:</span></div>
        <div *ngIf="isPlayValue" class="font-bold text-lg">
          {{durationActive$ | async | secondsToTimePipe}}
        </div>
      </div>
      <div class="flex-auto min-w-0 flex items-center justify-between">
        <div *ngIf="isPlayValue" class="text-sm leading-5 flex items-center gap-1">
          <mat-icon class="text-white icon-size-5" svgIcon="mat_outline:access_time"></mat-icon>
          <div class="flex items-center h-3 leading-3"><span>{{startedAt | date:'HH:mm'}}</span><span>-</span><span>{{currentDate | date:'HH:mm'}}</span></div>
        </div>
        <div class="flex items-center">
          <div class="truncate text-lg font-semibold">Today:</div>
          <div class="font-bold text-xl w-20 text-end">
            {{duration$ | async | secondsToTimePipe}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #playBtnTmpl2>
  <button class="w-8 h-8 rounded-full flex justify-center items-center border-2 border-white shadow-md" mat-ripple
    (click)="play()">
    <mat-icon class="icon-size-5 text-white" svgIcon="mat_solid:play_arrow" [matTooltip]="'Start tracking'"></mat-icon>
  </button>
</ng-template>

<ng-template #stopBtnTmpl2>
  <button class="w-8 h-8 rounded-full flex justify-center items-center border-2 border-white shadow-md" mat-ripple
    (click)="stop()">
    <mat-icon class="icon-size-5 text-white" svgIcon="mat_solid:stop" [matTooltip]="'Stop tracking'"></mat-icon>
  </button>
</ng-template>