import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivityUser } from 'app/models/activity-user';
import { ExportToExcelService } from 'app/services/export-to-excel.service';
import { secondsToTime } from 'app/utils/seconds-to-time';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, combineLatest, map, startWith, Subject, switchMap, tap, shareReplay, ReplaySubject, catchError, EMPTY } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { ReportsService } from 'app/services/report.service';
import { MatPaginator } from '@angular/material/paginator';

type FieldGroup = Extract<keyof ActivityUser, string>;

const commonLineSparklineOptions: Partial<ChartOptions> = {
  chart: {
    type: "line",
    width: '100%',
    height: '60px',
    sparkline: {
      enabled: true
    }
  },
  stroke: {
    width: 3,
    curve: "smooth",

  },
  tooltip: {
    enabled: false,
    theme: 'dark',
    fixed: {
      enabled: false
    },
    x: {
      show: true
    },
    marker: {
      show: false
    },
    y: {
      formatter: (v) => secondsToTime(v)
    }
  },
  xaxis: {
    type: 'datetime',
    labels: {
      datetimeUTC: false,
      datetimeFormatter: {
        year: 'yyyy',
        month: "MMM 'yy",
        day: 'dd MMM',
        hour: 'HH:mm',
      },
    }
  },
};

const ratioDictionaryMap = {
  UserId: 'UserName',
  PrjId: 'PrjName',
  TaskId: 'TaskName'
};

const ratioDictionaryMap2 = {
  UserId: 'User',
  PrjId: 'Project',
  TaskId: 'Task'
};

interface TotalTime {
  all: number,
  manual: number,
  auto: number
}

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexTooltip,
  ApexXAxis,
  ApexLegend,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexPlotOptions,
  ApexYAxis,
  ApexGrid
} from "ng-apexcharts";
import { OrganizationService } from 'app/services/organization.service';


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  markers: any; //ApexMarkers;
  stroke: any; //ApexStroke;
  yaxis: ApexYAxis | ApexYAxis[];
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels;
  colors: string[];
  labels: string[] | number[];
  title: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
  grid: ApexGrid;
};

@Component({
  selector: 'tm-activity-data-table2',
  templateUrl: './activity-data-table2.component.html',
  styleUrls: ['./activity-data-table2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ActivityDataTable2Component {
  requestRange: { start: number, end: number };
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  columnsToDisplay = ['name', 'totalTracked', 'activity', 'chart'];
  selectedGroupField = '';
  dataReport: Array<ActivityUser> = [];

  readonly users$ = new BehaviorSubject<Array<any>>([]);
  readonly selectedUsers$ = new Subject<Array<number>>();
  readonly selectedRange$ = new ReplaySubject<{ start: number, end: number }>();
  readonly grouping$ = new Subject<FieldGroup>();
  readonly loading$ = new BehaviorSubject<boolean>(true);
  readonly totalTime$ = new ReplaySubject<TotalTime>();

  constructor(
    private exportService: ExportToExcelService,
    private readonly reportsService: ReportsService,
    private readonly fb: FormBuilder,
  ) { }

  readonly dataReport$ = combineLatest([
    this.selectedRange$.pipe(
      tap(() => this.loading$.next(true)),
      switchMap((range) => {
        let params = new HttpParams();
        params = params.append('start', range.start);
        params = params.append('end', range.end);
        params = params.append('groupBy', 'user');
        return this.reportsService.getActivityUsers(params);
      }
      ),
      tap(data => {
        const range = {
          start: new Date(data.beginDate).setHours(0, 0, 0, 0),
          end: new Date(data.endDate).setHours(0, 0, 0, 0),
        }
        this.requestRange = range;
      }),
      map((data) => data['report_data']),
      tap((data: Array<ActivityUser>) => {
        const usersMap = new Map<number, string>();
        data.forEach((user) => usersMap.set(user.UserId, user.UserName));
        const users = Array.from(usersMap).map(([key, value]) => ({ UserId: key, UserName: value }));
        this.users$.next(users);
        this.dataReport = data;
      }),
      map(usersActivity => usersActivity.map(userActivity => ({
        ...userActivity,
        ExitDate: +new Date(userActivity.ExitDate),
        CreatedDate: +new Date(userActivity.CreatedDate),
        ExitTime: userActivity.ExitDate,
        CreatedTime: userActivity.CreatedDate
      }))),
    ),
    this.selectedUsers$,
    this.grouping$.pipe(
      startWith('UserId' as FieldGroup),
      tap((v) => this.selectedGroupField = ratioDictionaryMap2[v])
    )
  ]).pipe(
    map(([data, usersIdx, fieldGroup]) => {
      const filteredData = data.filter((item) => (usersIdx.length > 0) ? usersIdx.findIndex(id => id === item.UserId) > -1 : true);
      const groupedData = this.group(filteredData, fieldGroup)
      const totalTime = groupedData.reduce((acc, item) => {
        acc.all += item.totalTracked;
        acc.auto += item.autoTracked;
        acc.manual += item.manualTracked;
        return acc;
      }, { all: 0, manual: 0, auto: 0 });
      this.totalTime$.next(totalTime);
      const dataSource = new MatTableDataSource(groupedData);
      dataSource.paginator = this.paginator;
      dataSource.sort = this.sort;
      return dataSource;
    }),
    tap(() => this.loading$.next(false)),
    catchError(() => {
      this.loading$.next(false);
      return EMPTY
    }),
    shareReplay(1),
  );

  private group(usersActivity: Array<any>, fieldGroup?: FieldGroup) {
    return this.groupByLevel1(usersActivity, fieldGroup);
  }

  private setParams(range: { start: number, end: number }): HttpParams {
    let params = new HttpParams();
    const start = new Date(new Date(range.start).setHours(0, 0, 0, 0)).toJSON().replace('Z', '');
    const end = new Date(new Date(range.end).setHours(23, 59, 59, 999)).toJSON().replace('Z', '');
    params = params.append('BeginDate', start);
    params = params.append('EndDate', end);
    return params;
  }

  static initDataChart(start: number | Date, end: number | Date): (Map<number, number>) {
    const data: Map<number, number> = new Map();
    let i = +start;
    while (i <= +end) {
      data.set(i, 0);
      i = new Date(i).setDate(new Date(i).getDate() + 1);
    }
    return data;
  }

  private groupByLevel1(usersActivity: Array<ActivityUser>, fieldGroup: FieldGroup = 'UserId'): any[] {
    const map = new Map<unknown, { name: unknown, children: Array<ActivityUser> }>();
    usersActivity.forEach((user) => {
      const valueGroup = user[fieldGroup];
      if (map.has(valueGroup)) {
        map.get(valueGroup).children.push(user)
      } else map.set(valueGroup, { name: user[ratioDictionaryMap[fieldGroup] || fieldGroup], children: [user] });
    });
    const data = Array.from(map).map(([, value]) => ({ name: value.name?.toString(), children: value.children })).sort((v1, v2) => v1.name.localeCompare(v2.name));
    return this.calculate(data);
  }

  private chartLineGenerated(): Map<number, number> {
    const initData = ActivityDataTable2Component.initDataChart(this.requestRange.start, this.requestRange.end);
    return initData;
  }

  private calculate(data: Array<{ name: string, children: Array<ActivityUser> }>) {
    const initChartLine = this.chartLineGenerated();
    const result = data.map(item => {
      const stats = item.children.reduce((acc, curr) => {
        acc.totalTracked += curr.Total_Tracked;
        acc.manualTracked += curr.Manual;
        acc.autoTracked += curr.Tracked;
        acc.activity += curr.Activity;
        const date = +new Date(curr.GroupDate);
        acc.chartLine.set(date, acc.chartLine.get(date) + curr.Total_Tracked);
        return acc;
      }, { activity: 0, totalTracked: 0, chartLine: new Map(initChartLine)/*, keyPressChart: new Map<string, number>(), radialBar: new Map<string, number>()*/, autoTracked: 0, manualTracked: 0 });
      const chartLineSort = Array.from(stats.chartLine, ([key, value]) => ({ labels: key, series: value })).sort((v1, v2) => v1.labels - v2.labels);
      return ({
        ...item,
        ...stats,
        chartLine: {
          ...commonLineSparklineOptions,
          series: [{
            data: [...chartLineSort.map(line => line.series)], name: 'Total Tracked'
          }],
          labels: [...chartLineSort.map(line => line.labels)]
        },
      });
    });
    return result;
  }

  exportToExcel(): void {
    this.exportService.exportToCsv(this.dataReport, 'Activity_report');
  }
}
