<div class="flex flex-col">
  <!-- Header -->
  <div class="flex items-center justify-between mb-4">
      <div class="text-2xl font-semibold">Edit category</div>
      <button
          matDialogClose
          mat-icon-button>
          <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
      </button>
  </div>
  <div class="flex flex-col gap-2" [formGroup]="categoryForm">
    <mat-form-field class="fuse-mat-dense" [subscriptSizing]="'dynamic'">
      <mat-label>Choose an type category</mat-label>
      <mat-select formControlName="type" name="type" placeholder="Choose an type category">
        <mat-option value="job">Job</mat-option>
        <mat-option value="ticket">Ticket</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="fuse-mat-dense" [subscriptSizing]="'dynamic'">
      <mat-label>Choose an parent category</mat-label>
      <mat-select formControlName="parentId" name="parentId" placeholder="Choose an parent category">
        <mat-option [value]="category.id"
          *ngFor="let category of rootCategories">{{category.name}}</mat-option>
      </mat-select>
    </mat-form-field>
        <!-- New category -->
    <mat-form-field class="fuse-mat-dense" [subscriptSizing]="'dynamic'">
        <input
            name="name"
            [autocomplete]="'off'"
            [placeholder]="'Create new category'"
            matInput
            formControlName="name"
            (keydown.enter)="addCategory()">
        <button
            [class.invisible]="!isCreateNewCategory"
            mat-icon-button
            (click)="addCategory()"
            matSuffix>
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
        </button>
    </mat-form-field>
  </div>
  <mat-divider *ngIf="parentIdFC.value" class="mt-4"></mat-divider>
    <!-- Labels -->
    <div class="max-h-80 overflow-auto mt-4">
            <ng-container *ngFor="let category of filteredCategories; trackBy: trackByFn; else loading; empty: notFoundCategories;">
                <mat-form-field class="fuse-mat-dense fuse-mat-rounded w-full mb-1" [subscriptSizing]="'dynamic'">
                    <button
                        mat-icon-button
                        matPrefix
                        (click)="$event.stopPropagation();deleteCategory(category.id)">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                    </button>
                    <input
                        [(ngModel)]="category.name"
                        (input)="updateCategory$.next(category)"
                        [autocomplete]="'off'"
                        required
                        matInput
                        >
                </mat-form-field>
            </ng-container>
    </div>
</div>

<ng-template #notFoundCategories>
  <div class="text-xl text-center">Not found categories!</div>
</ng-template>

<ng-template #loading>
  <div class="flex justify-center">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
</ng-template>