import { inject, Injectable } from '@angular/core';
import { WINDOW } from 'app/core/core.module';
import { BehaviorSubject, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private readonly _isOnline$ = new BehaviorSubject<boolean>(true);
  private readonly window = inject(WINDOW);
  readonly isOnline$ = this._isOnline$.asObservable().pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor() {
    this.window.addEventListener('online', () => this._isOnline$.next(true));
    this.window.addEventListener('offline', () => this._isOnline$.next(false));
  }
}