import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/core/material/material.module';
import { OrganizationAddFormComponent } from '../../components/organization-add-form/organization-add-form.component';
import { CommonModule } from '@angular/common';
import { OrganizationService } from 'app/services/organization.service';
import { FuseCardModule } from '@fuse/components/card';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'tm-registration-complete',
  templateUrl: './registration-complete.component.html',
  styleUrls: ['./registration-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    OrganizationAddFormComponent,
    FuseCardModule
  ]
})
export class RegistrationCompleteComponent {
  constructor(
    private readonly auth0Service: AuthService,
    private readonly organizationService: OrganizationService) {
  }

  done(organizationId: string): void {
    this.organizationService.select(organizationId);
  }

  signOut(): void {
    this.auth0Service.logout();
  }
}