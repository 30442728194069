<mat-form-field [subscriptSizing]="'dynamic'" class="w-full fuse-mat-dense">
  <mat-label>{{label}}</mat-label>
  <mat-chip-grid #chipGridProjects>
    <mat-chip-row *ngFor="let project of projectsFC?.value" (removed)="removeProject(project.id)">
      {{project.name}}
      <mat-icon class="icon-size-5" matChipRemove>cancel</mat-icon>
    </mat-chip-row>
    <input [placeholder]="'Search...'" [matChipInputFor]="chipGridProjects" [matAutocomplete]="autoProjects"
      [formControl]="projectsSearchFC" #projectSearchInput>
    <mat-autocomplete autoActiveFirstOption #autoProjects="matAutocomplete" (optionSelected)="projectSelected($event)">
      <mat-option *ngFor="let project of projects$ | async" [value]="project">
        {{project.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-chip-grid>
  <button *ngIf="projectsFC.value.length > 0" mat-icon-button matSuffix (click)="removeProjects()">
    <mat-icon svgIcon="mat_outline:clear_all"></mat-icon>
  </button>
  <mat-error *ngIf="projectsFC.hasError('required')">Projects is required</mat-error>
</mat-form-field>