<button mat-icon-button [matMenuTriggerFor]="menu">
  <span class="relative">
   <mat-icon svgIcon="mat_outline:admin_panel_settings"></mat-icon>
  </span>
</button>

<mat-menu [xPosition]="'before'" #menu="matMenu">
  <button mat-menu-item (click)="addCategory()" matTooltip="Add new category">
    <mat-icon svgIcon="mat_outline:category"></mat-icon>
    <span>Add category</span>
  </button>
</mat-menu>