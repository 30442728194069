import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OrganizationAddFormComponent } from '../organization-add-form/organization-add-form.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/core/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@Component({
  selector: 'tm-organization-add',
  templateUrl: './organization-add.component.html',
  styleUrls: ['./organization-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    OrganizationAddFormComponent,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class OrganizationAddComponent {

  constructor(private dialogRef: MatDialogRef<OrganizationAddComponent>) { }

  done(id: string): void {
    this.dialogRef.close(id);
  }
}