<div class="flex flex-col flex-auto min-w-0 p-4 gap-2 w-full">
  <div class="text-3xl font-semibold">Time & activity report</div>
  <div class="flex flex-col items-center gap-2 sm:flex-row sm:ml-auto">
    <tm-date-range-picker class="w-full" (selectRange)="selectedRange$.next($event)"></tm-date-range-picker>
  </div>
  <div class="flex flex-col">
      <div class="flex items-center mb-2 justify-between">
        <button mat-flat-button [matMenuTriggerFor]="groupingMenu" class="text-secondary">
            <mat-icon svgIcon="heroicons_outline:menu-alt-2"></mat-icon>
            <span class="ml-3">Group by:</span>
            <span class="ml-2 text-primary-500">{{selectedGroupField}}</span>
        </button>
        <mat-menu #groupingMenu="matMenu" xPosition="before">
          <button mat-menu-item (click)="grouping$.next('CreatedDate')">
                <mat-icon svgIcon="heroicons_outline:calendar"></mat-icon>
                <span>Date</span>
            </button>
            <button mat-menu-item (click)="grouping$.next('TaskId')">
                <mat-icon svgIcon="mat_outline:task"></mat-icon>
                <span>Task</span>
            </button>
            <button mat-menu-item (click)="grouping$.next('PrjId')">
                <mat-icon svgIcon="heroicons_outline:briefcase"></mat-icon>
                <span>Project</span>
            </button>
        </mat-menu>
        <button (click)="exportToExcel()" mat-flat-button [color]="'primary'">
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:save'"></mat-icon>
            <span class="ml-2">Export</span>
        </button>
      </div>
    <table mat-table [dataSource]="(report$ | async)" matSort class="mat-elevation-z0">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{selectedGroupField}} </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="selectedGroupField === 'Date'; else noDatePipe">
            {{element.date | date}}
          </ng-container>
          <ng-template #noDatePipe>
            {{element.date}}
          </ng-template>
            
        </td>
        <td mat-footer-cell *matFooterCellDef class="font-semibold"> Total </td>
      </ng-container>
      <ng-container matColumnDef="totalTracked">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Time Worked </th>
        <td mat-cell *matCellDef="let element">
            <div class="flex flex-col whitespace-nowrap">
                <div>{{element.totalTracked | secondsToTime}}</div>
                <div [matTooltip]="'Automatic'" class="text-secondary text-sm leading-none" *ngIf="element.autoTracked > 0">A: {{element.autoTracked | secondsToTime}}</div>
                <div [matTooltip]="'Manual'" class="text-secondary text-sm leading-none" *ngIf="element.manualTracked > 0">M: {{element.manualTracked | secondsToTime}}</div>
            </div>
          </td>
        <td mat-footer-cell *matFooterCellDef>
          <div class="flex flex-col font-semibold whitespace-nowrap" *ngIf="totalTime$ | async as totalTime">
            <div>{{totalTime.all | secondsToTime}}</div>
            <div [matTooltip]="'Automatic'" class="text-secondary text-sm leading-none" *ngIf="totalTime.auto > 0">A: {{totalTime.auto | secondsToTime}}</div>
            <div [matTooltip]="'Manual'" class="text-secondary text-sm leading-none" *ngIf="totalTime.manual > 0">M: {{totalTime.manual | secondsToTime}}</div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="activity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Activity </th>
        <td mat-cell *matCellDef="let element"> {{element.activity}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
      <!-- <ng-container *ngIf="totalTime$ | async as totalTime">
        <ng-container *ngIf="totalTime.auto > 0 || totalTime.manual > 0"> -->
          <tr mat-footer-row *matFooterRowDef="columnsToDisplay; sticky: true"></tr>
        <!-- </ng-container>
      </ng-container> -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-footer-cell" colspan="3">
          <div class="flex-auto">
            <div class="text-secondary text-2xl text-center my-4">No data found!</div>
          </div>
        </td>
      </tr>
    </table>
    <mat-divider></mat-divider>
    <mat-paginator [pageSizeOptions]="[10, 25, 50]" aria-label="Select page"></mat-paginator>
  </div>
</div>

<ng-template #noDataFound>
  <fuse-card class="p-4 justify-center rounded-none">
      <div class="text-secondary text-2xl text-center my-4">No data found!</div>
  </fuse-card>
</ng-template>