import { inject, NgZone } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { NetworkService } from "app/services/network.service";
import { OrganizationService } from "app/services/organization.service";
import { combineLatest, map, Observable, ReplaySubject, shareReplay, skip, Subject } from "rxjs";

export abstract class SignalRBase {
  protected readonly _reconnected$ = new Subject<void>();
  protected readonly _isConnect$ = new ReplaySubject<boolean>(1);
  protected readonly _error$ = new Subject<void>();
  protected readonly auth0Service = inject(AuthService);
  protected readonly organizationService = inject(OrganizationService);
  protected readonly zone = inject(NgZone);
  protected readonly networkService = inject(NetworkService);

  readonly isConnected$ = combineLatest({ isConnect: this._isConnect$, isOnline: this.networkService.isOnline$ }).pipe(
    map(({ isConnect, isOnline }) => (isConnect === true && isOnline === true) ? true : false),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  readonly isReconnected$ = this._reconnected$.asObservable().pipe(
    skip(1)
  );

  protected connectionErrorHandler(err?: any) {
    console.error('Error hub connection: ' + err);
    this._error$.next();
    this._isConnect$.next(false);
  }
}