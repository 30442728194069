import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MaterialModule } from 'app/core/material/material.module';
import { timeZonesNames } from "@vvo/tzdb";
import { debounceTime, map, startWith } from 'rxjs';

@Component({
  selector: 'tm-time-zone-select',
  standalone: true,
  imports: [CommonModule, NgxMatSelectSearchModule, MaterialModule, ReactiveFormsModule],
  templateUrl: './time-zone-select.component.html',
  styleUrls: ['./time-zone-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true })
    }
  ]
})
export class TimeZoneSelectComponent {
  private readonly timeZones = timeZonesNames;
  readonly timeZonesFilterCtrl = new FormControl<string>('');

  readonly timeZoneListFilter$ = this.timeZonesFilterCtrl.valueChanges.pipe(
    startWith(''),
    debounceTime(150),
    map((searchText) => {
      if (searchText) {
        return this.timeZones.filter(timezone => timezone.toLowerCase().indexOf((searchText || '').toLowerCase()) > -1);
      } else return this.timeZones
    })
  );
}
