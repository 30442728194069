import { ObjectKind } from "app/services/objects-session"

export interface ClientState {
  client: {
    status: ClientStatus,
    session?: ClientSession | null,
    timeWorked: number,
    committedAt: string
    version: string,
    pauseToken?: string
  }
}

export interface ClientSession {
  id: string,
  organizationId?: string,
  userId?: string,
  status?: string,
  objectId: string,
  objectKind: ObjectKind,
  startedAt?: string,
  progressAt?: string,
  duration?: string
}

export enum ClientStatus {
  Started = 'Started',
  Stopped = 'Stopped',
  Paused = 'Paused'
}