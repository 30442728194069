import { InjectionToken, inject } from "@angular/core";
import { Observable, combineLatest, map, shareReplay } from "rxjs";
import { ProjectService } from "./project.service";
import { Project, ProjectStatus } from "app/models/project/project";
import { TicketService } from "./ticket.service";
import { TicketStatus } from "app/models/ticket/ticket";
import { OrganizationService } from "./organization.service";

export interface ObjectSession {
  id: string,
  title: string,
  objectKind: ObjectKind
  category: string,
  project?: { id: string, name: string }
}
export enum ObjectKind {
  Ticket = 'Ticket',
  Project = 'Project'
}

export enum ObjectKindUI {
  Ticket = 'Task',
  Project = 'Project'
}

export const OBJECTSSESSION = new InjectionToken<Observable<Array<ObjectSession>>>('OBJECTSSESSION',
  {
    factory: () => {
      const projectService = inject(ProjectService);
      const ticketService = inject(TicketService);

      const getTickets = () => {
        return ticketService.tickets$.pipe(
          map(tickets => tickets.filter(ticket => ticket.status === TicketStatus.Open)),
          map(tickets => tickets.map(ticket => ({ id: ticket.id, title: ticket.title, objectKind: ObjectKind.Ticket, category: 'Tasks', project: ticket.project })))
        );
      }

      const getProjects = () => {
        return projectService.userProjects$.pipe(
          map(projects => projects.filter(project => project.status === ProjectStatus.Normal)),
          map(projects => projects.map(project => ({ id: project.id, title: project.name, objectKind: ObjectKind.Project, category: 'Projects' })))
        );
      }
      return combineLatest({
        tickets: getTickets(),
        projects: getProjects(),
      }).pipe(
        map(({ tickets, projects }) => [...tickets, ...projects]),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    }
  }
);

export const ORGANIZATIONOBJECTSSESSION = new InjectionToken<Observable<Array<ObjectSession>>>('ORGANIZATIONOBJECTSSESSION',
  {
    factory: () => {
      const projectService = inject(ProjectService);
      const organizationService = inject(OrganizationService);

      const getTickets = () => {
        return organizationService.getTickets().pipe(
          map(tickets => tickets.filter(ticket => ticket.status === TicketStatus.Open)),
          map(tickets => tickets.map(ticket => ({ id: ticket.id, title: ticket.title, objectKind: ObjectKind.Ticket, category: 'Tasks', project: ticket.project })))
        );
      }

      const getProjects = () => {
        return projectService.userProjects$.pipe(
          map(projects => projects.filter(project => project.status === ProjectStatus.Normal)),
          map(projects => projects.map(project => ({ id: project.id, title: project.name, objectKind: ObjectKind.Project, category: 'Projects' })))
        );
      }
      return combineLatest({
        tickets: getTickets(),
        projects: getProjects(),
      }).pipe(
        map(({ tickets, projects }) => [...tickets, ...projects]),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    }
  }
);