import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppInfoComponent } from './components/app-info/app-info.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/core/material/material.module';
import { FileUploadModule } from 'ng2-file-upload';
import { ReportIssueFormComponent } from './components/report-issue-form/report-issue-form.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FuseAlertModule } from '@fuse/components/alert';

@NgModule({
  declarations: [
    AppInfoComponent,
    ReportIssueFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    FileUploadModule,
    NgxSkeletonLoaderModule,
    FuseAlertModule
  ],
  exports: [
    AppInfoComponent,
    FileUploadModule
  ]
})
export class AppServiceModule { }
