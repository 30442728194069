import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MaterialModule } from 'app/core/material/material.module';
import { ControlContainer, FormControl, ReactiveFormsModule } from '@angular/forms';

import { CountryLanguageService } from 'app/services/country-language.service';
import { debounceTime, map, startWith } from 'rxjs';

@Component({
  selector: 'tm-country-code-select',
  standalone: true,
  imports: [CommonModule, NgxMatSelectSearchModule, MaterialModule, ReactiveFormsModule,],
  templateUrl: './country-code-select.component.html',
  styleUrls: ['./country-code-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true })
    }
  ]
})
export class CountryCodeSelectComponent {
  readonly countriesFilterCtrl = new FormControl<string>('');
  constructor(private readonly countryLanguageService: CountryLanguageService) { }
  readonly countryListFilter$ = this.countriesFilterCtrl.valueChanges.pipe(
    startWith(''),
    debounceTime(150),
    map((searchText) => {
      if (searchText) {
        return this.countryList.filter(country => country.label?.toLowerCase().indexOf((searchText || '').toLowerCase()) > -1);
      } else return this.countryList
    })
  );
  private split(locales: Array<string>): Array<string> {
    const countryCodes = locales.map((locale) => {
      const [_, countryCode, countryCode2] = locale.split('-');
      return countryCode
    }).filter((countryCode) => !!countryCode).map(countryCode => countryCode.toUpperCase());
    const uniqueCountryCodes = new Set(countryCodes);
    return Array.from(uniqueCountryCodes, (countryCode) => countryCode);
  }
  readonly countryList = this.split(this.countryLanguageService.locales).map(countryCode => ({ value: countryCode, label: this.countryLanguageService.countryLanguage.getCountry(countryCode, (err, country) => (err) ? '' : country?.name || '') as string || countryCode })).sort((v1, v2) => v1.label.localeCompare(v2.label));

}