<div class="flex flex-col gap-4">
  <div class="flex items-center justify-between">
    <div class="text-2xl">Add task</div>
    <button mat-icon-button matDialogClose><mat-icon svgIcon="mat_solid:close"></mat-icon></button>
  </div>
  <div [formGroup]="taskForm" class="flex flex-col">
    <div class="flex flex-col gap-2">
      <mat-form-field class="fuse-mat-dense" [subscriptSizing]="'dynamic'">
        <mat-label>Task Name</mat-label>
        <input matInput formControlName="title" name="title">
        <mat-error>Task Name is required</mat-error>
      </mat-form-field>
      <mat-form-field class="fuse-mat-dense" [subscriptSizing]="'dynamic'">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" name="description"></textarea>
      </mat-form-field>
      <mat-form-field class="fuse-mat-dense" [subscriptSizing]="'dynamic'">
        <mat-label>User</mat-label>
        <mat-select formControlName="assigneeId">
          <mat-option [value]="null">You</mat-option>
          <mat-option [value]="user.id" *ngFor="let user of users$ | async">{{user.displayName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="flex gap-2 ml-auto">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button [color]="'primary'" [disabled]="formInValid || (submitting$ | async)" (click)="submit()">
      <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
      <span class="ml-2 mr-1">Add Task</span>
    </button>
  </div>
</div>