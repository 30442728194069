<!-- <div
  class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 bg-card">
  <div
    class="relative hidden md:flex flex-auto items-center justify-center w-1/2 h-full p-16 lg:px-28 overflow-hidden bg-gray-800 dark:border-r">
    <svg class="absolute inset-0 pointer-events-none" viewBox="0 0 960 540" width="100%" height="100%"
      preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
      <g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
        <circle r="234" cx="196" cy="23"></circle>
        <circle r="234" cx="790" cy="491"></circle>
      </g>
    </svg>
    <svg class="absolute -top-16 -right-16 text-gray-700" viewBox="0 0 220 192" width="220" height="192" fill="none">
      <defs>
        <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
          patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
        </pattern>
      </defs>
      <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
    </svg>
    <div class="z-10 relative w-full max-w-2xl">
      <div class="text-7xl font-bold leading-none text-gray-100">
        <div>Welcome to SpectroTime</div>
      </div>
      <div class="mt-6 text-lg tracking-tight leading-6 text-gray-400">
        <ng-container [ngTemplateOutlet]="text"></ng-container>
      </div>
    </div>
  </div>
  <div class="md:flex md:items-center md:justify-start w-full sm:w-auto md:h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16">
    <div class="w-full sm:w-120 mx-auto sm:mx-0">
      <div class="flex flex-col py-4">
        <button mat-icon-button class="ml-auto" (click)="signOut()" [matTooltip]="'Log Out'">
          <mat-icon svgIcon="mat_outline:logout"></mat-icon>
        </button>
      </div>
      <div class="z-10 w-full md:hidden">
        <div class="text-7xl font-bold leading-none text-gray-700">
          <div class="text-center">Welcome to SpectroTime</div>
        </div>
        <div class="mt-6 text-lg tracking-tight leading-6 text-gray-500 text-center mb-4">
          <ng-container [ngTemplateOutlet]="text"></ng-container>
        </div>
      </div>
      <div class="text-3xl font-semibold">Company Details</div>
      <tm-organization-add-form (done)="done($event)"></tm-organization-add-form>
    </div>
  </div>
</div>

<ng-template #text>
  We're thrilled to have you on board. Let's kickstart your journey by setting up your company account. This is where you'll establish your company's presence within our platform and tailor your experience to your industry.
</ng-template> -->


<div class="flex items-center justify-center w-full">
  <div class="flex p-8 flex-col w-full sm:w-140 rounded-none sm:rounded-3xl bg-white">
    <div class="flex justify-center mb-8">
      <img [src]="'./assets/images/logo/logo.svg'" class="w-14 h-14">
    </div>
    <div class="text-3xl font-semibold">Company Details</div>
    <div class="mt-6 text-lg tracking-tight leading-6 text-gray-500 text-center mb-4">
      We're thrilled to have you on board. Let's kickstart your journey by setting up your company account. This is
      where you'll establish your company's presence within our platform and tailor your experience to your industry.
    </div>
    <tm-organization-add-form (done)="done($event)"></tm-organization-add-form>
  </div>
</div>