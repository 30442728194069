<button mat-icon-button [matMenuTriggerFor]="oranizationActions">
  <span class="relative">
    <mat-icon [svgIcon]="'mat_outline:business'"></mat-icon>
  </span>
</button>

<mat-menu [xPosition]="'before'" #oranizationActions="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="organizationsMenu">
    <div class="flex items-center">
      <mat-icon [svgIcon]="'mat_outline:follow_the_signs'"></mat-icon>
      <span>{{selectedOrganization.name}}</span>
    </div>
  </button>
  <button mat-menu-item [routerLink]="'/settings/organizations'" *ngIf="isOwnerOrg$ | async">
    <mat-icon svgIcon="mat_outline:settings_applications"></mat-icon>
    <span>Settings</span>
  </button>
  <button mat-menu-item (click)="addCategory()" matTooltip="Add new category" *ngIf="isOwnerOrg$ | async">
    <mat-icon svgIcon="mat_outline:category"></mat-icon>
    <span>Add category</span>
  </button>
  <!-- <button
        *ngIf="organizations.length > 0"
        mat-menu-item
        [matMenuTriggerFor]="organizationsMenu">
        <mat-icon [svgIcon]="'published_with_changes'"></mat-icon>
        <span>Change</span>
    </button> -->


  <!-- <button
        mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Sign out</span>
    </button> -->
</mat-menu>

<mat-menu #organizationsMenu="matMenu">
  <div class="flex items-center" *ngFor="let organization of organizations">
    <button [disabled]="organization.id === selectedOrganizationId" mat-menu-item
      (click)="selectOrganization(organization?.id)">
      <span>{{organization?.name}}</span>
    </button>
    <mat-icon class="ml-auto mr-2" *ngIf="(organization.id === selectedOrganizationId)"
      svgIcon="heroicons_outline:check"></mat-icon>
  </div>
</mat-menu>