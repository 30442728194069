import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject, debounceTime, map, merge, Observable, startWith, switchMap } from 'rxjs';
import { Project, ProjectStatus } from 'app/models/project/project';
import { ProjectService } from 'app/services/project.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { lengthValidator } from 'app/utils/validators/length';


@Component({
  selector: 'tm-autocopmlete-select-projects',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatIconModule, MatFormFieldModule, MatChipsModule, MatAutocompleteModule, MatButtonModule],
  templateUrl: './autocomplete-select-projects.component.html',
  styleUrls: ['./autocomplete-select-projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true })
    }
  ]
})
export class AutocompleteSelectProjectsComponent implements OnDestroy, OnInit {
  @Input('label') label = 'Select Projects';
  @Input('required') isRequired = true;
  @ViewChild('projectSearchInput') projectSearchInput: ElementRef<HTMLInputElement>;
  readonly projectsSearchFC = new FormControl<string>('');
  private readonly parentContainer = inject(ControlContainer);
  @Output() init = new EventEmitter<void>();

  private readonly excludingProjects$ = new BehaviorSubject<Array<Project>>([]);
  @Input('excludingProjects') set excludingProjects(projects: Array<Project>) {
    if (Array.isArray(projects)) {
      this.excludingProjects$.next(projects)
    }
  }

  get parentForm() {
    return this.parentContainer.control as FormGroup;
  }

  get projectsFC() {
    return this.parentForm.get('projects');
  }

  constructor(
    private readonly projectService: ProjectService) {
  }

  projects$: Observable<Array<Project>>;

  projectSelected(e: MatAutocompleteSelectedEvent) {
    const project = e.option.value;
    if (project) {
      this.projectsFC.setValue([...this.projectsFC.value, project]);
      this.projectSearchInput.nativeElement.value = '';
      this.projectsSearchFC.setValue('', { emitEvent: false });
    }
  }
  removeProject(id: string) {
    const projects = (this.projectsFC.value as Array<Project>).filter(project => project.id !== id);
    this.projectsFC.setValue(projects);
  }

  removeProjects() {
    this.projectsFC.setValue([]);
  }

  ngOnInit(): void {
    this.parentForm.addControl('projects', new FormControl<Array<Project>>([]));
    if (this.isRequired) {
      this.projectsFC.setValidators(lengthValidator());
      this.projectsFC.updateValueAndValidity();
    }

    this.projects$ = merge(this.projectsFC.valueChanges.pipe(startWith([])), this.projectsSearchFC.valueChanges.pipe(startWith('')), this.excludingProjects$).pipe(
      debounceTime(150),
      switchMap(() => {
        const selectedProjects = this.projectsFC.value;
        const projectsSearch = this.projectsSearchFC.value || '';
        const excludingProjects = this.excludingProjects$.value;
        return this.projectService.projects$.pipe(
          map((projects) => projects.filter(project => project.status === ProjectStatus.Normal)),
          map((projects) => projects.filter(project => (!selectedProjects.map(p => p.id).includes(project.id)) && (!excludingProjects.map(p => p.id).includes(project.id)) && project.name.toLowerCase().includes(projectsSearch?.toLowerCase()?.trim())))
        )
      })
    );

    // this.projects$ = combineLatest({
    //   projects: this.projectService.projects$.pipe(map((projects) => projects.filter(project => project.status === ProjectStatus.Normal))),
    //   projectsSearch: this.projectsSearchFC.valueChanges.pipe(startWith(''), debounceTime(150)),
    //   selectedProjects: this.projectsFC.valueChanges.pipe(startWith([])),
    //   excludingProjects: this.excludingProjects$
    // }).pipe(
    //   map(({ projects, projectsSearch, selectedProjects, excludingProjects }) => {
    //     const searchText = projectsSearch.toLowerCase().trim();
    //     console.log(projects, projectsSearch, selectedProjects, excludingProjects)
    //     return projects.filter(project => !selectedProjects.map(p => p.id).includes(project.id) && !excludingProjects.map(p => p.id).includes(project.id) && project.name.toLowerCase().includes(searchText))
    //   })
    // );

    this.init.emit();
  }

  ngOnDestroy(): void {
    this.parentForm.removeControl('projects');
  }
}