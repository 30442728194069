<div class="flex flex-col gap-1 items-center">
  <div class="flex items-center justify-between gap-2">
    <div class="text-sm md:text-md leading-4">
      Application has been updated! Click to 'Update application' to get refreshed!
    </div>
    <button mat-icon-button (click)="snackBarRef.dismiss()">
      <mat-icon svgIcon="mat_outline:close"></mat-icon>
    </button>
  </div>
  <button mat-stroked-button color="primary" (click)="update()">
    Update application
  </button>
</div>