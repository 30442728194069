import { Injectable, TemplateRef, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';

export interface HubspotField {
  templateRef?: TemplateRef<any>,
  fieldType: string,
  hidden: boolean,
  label: string,
  name: string,
  objectTypeId: string,
  required: boolean,
  validation?: {
    blockedEmailDomains: Array<any>,
    useDefaultBlockList: boolean
  }
}

export interface HubspotGroupsField {
  fields: HubspotField[]
  groupType: string,
  richTextType: string,
}

export interface HubspotForm {
  fields?: Array<HubspotField>,
  archived: boolean,
  configuration: {
    language: string,
    cloneable: boolean
  },
  createdAt: string,
  // displayOptions:{ renderRawHtml: boolean, theme: string, submitButtonText: string },
  fieldGroups: HubspotGroupsField[],
  formType: string,
  id: string,
  legalConsentOptions: LegalConsentOptions
  name: string,
  updatedAt: string,
}

export type LegalConsentOptions = LegalConsentOptionsNone | LegalConsentOptionsLegitimateInterest | LegalConsentOptionsImplicitConsentToProcess | LegalConsentOptionsExplicitConsentToProcess;

export interface LegalConsentOptionsNone {
  type: 'none',
}

export interface LegalConsentOptionsLegitimateInterest {
  type: 'legitimate_interest',
  subscriptionTypeIds: Array<any>,
  lawfulBasis: string,
  privacyText: string
}

export interface LegalConsentOptionsImplicitConsentToProcess {
  type: 'implicit_consent_to_process',
  communicationConsentText: string,
  communicationsCheckboxes: Array<CommunicationsCheckboxes>,
  consentToProcessText: string,
  privacyText: string
}

export interface LegalConsentOptionsExplicitConsentToProcess {
  type: 'explicit_consent_to_process',
  communicationConsentText: string,
  communicationsCheckboxes: Array<CommunicationsCheckboxes>,
  consentToProcessText: string,
  consentToProcessCheckboxLabel: string,
  consentToProcessFooterText: string
  privacyText: string
}

export interface CommunicationsCheckboxes {
  label: string,
  required: boolean,
  subscriptionTypeId: number
}


@Injectable({
  providedIn: 'root'
})
export class HubspotService {

  constructor(private httpClient: HttpClient, @Inject(APP_CONFIG) private appConfig: AppConfig) { }
  /*57d8b404-a4b2-46d5-a21e-146650fce7cc*/
  /*57cbb219-a4fa-4403-81e6-b43b3d936ba4*/
  getForm(formId = '57d8b404-a4b2-46d5-a21e-146650fce7cc'): Observable<HubspotForm> {
    return this.httpClient.get<HubspotForm>(`${this.appConfig.baseUrl}/crm/forms/${formId}`);
  }

  submitForm(formId: string, data): Observable<any> {
    return this.httpClient.post(`${this.appConfig.baseUrl}/crm/forms/${formId}/data`, data);
  }
}
