import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, from, map, shareReplay, tap } from 'rxjs';
import { SignalRService } from './signal-r.service';
import { ObjectKind } from './objects-session';
import { OrganizationService } from './organization.service';
import { DateTime } from 'luxon';
import { TimeTrackingWorkerService } from './time-tracking-worker.service';


@Injectable({
  providedIn: 'root'
})
export class TimeTrackingSWService {
  private _activeSessionStart: string = new Date().toJSON();
  static DELTA = 60;
  private readonly _isPlay$ = new BehaviorSubject<boolean>(false);
  private _timeWorkedState = 0;
  sessionId: string | null = null;
  private readonly _duration$ = new BehaviorSubject<number>(0);
  private readonly _error$ = new BehaviorSubject<string | null>(null);
  private readonly _currentDate$ = new BehaviorSubject<DateTime>(DateTime.now());
  private readonly signalService = inject(SignalRService);
  private readonly organizationService = inject(OrganizationService);
  private readonly timeTrackingWorkerService = inject(TimeTrackingWorkerService);

  constructor() {
    this.timeTrackingWorkerService.worker.addEventListener('message', (message: MessageEvent) => {
      const { duration } = message.data;
      this.tick(duration);
    });
  }

  get duration$() {
    return this._duration$.asObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  readonly durationActive$ = this.currentDate$.pipe(
    map(currentDate => currentDate.diff(DateTime.fromISO(this._activeSessionStart), 'seconds').seconds),
    filter(duration => duration > 0)
  );

  get error$() {
    return this._error$.asObservable().pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get currentDate$() {
    return this._currentDate$.pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get isPlay$() {
    return this._isPlay$.asObservable().pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get isPlayValue() {
    return this._isPlay$.value;
  }
  get isConnected$() {
    return this.signalService.isConnected$.pipe(
      tap((isConnected) => {
        if (!!isConnected)
          this._error$.next(null);
      })
    );
  }
  get isReconnected$() {
    return this.signalService.isReconnected$;
  }
  get clientStarted$() {
    return this.signalService.on('ClientStarted').pipe(
      map(args => {
        const [orgId, sessionId, objectId, objectKind, startedAt, connectId] = args;
        return { orgId, sessionId, objectId, objectKind, startedAt, connectId };
      })
    );
  }
  get clientStopped$() {
    return this.signalService.on('ClientStopped').pipe(
      map(args => {
        const [orgId, sessionId, stoppedAt, timeWorked, connectId] = args;
        return { orgId, sessionId, stoppedAt, timeWorked, connectId };
      })
    );
  }
  get clientPaused$() {
    return this.signalService.on('ClientPaused');
  }
  get clientTimeWorkedUpdated$() {
    return this.signalService.on('ClientTimeWorkedUpdated').pipe(
      map(args => {
        const [orgId, timeWorked, commitedAtt] = args;
        return { orgId, timeWorked, commitedAtt };
      })
    );
  }
  get clientSessionObjectChanged$() {
    return this.signalService.on('ClientSessionObjectChanged').pipe(
      map(args => {
        const [orgId, sessionId, objectId, objectKind, connectId] = args;
        return { orgId, sessionId, objectId, objectKind, connectId };
      })
    );
  }

  get clientSessionResumed$() {
    return this.signalService.on('ClientResumed').pipe(
      map(args => {
        const [orgId] = args;
        return { orgId };
      })
    );
  }

  get sessionCreated$() {
    return this.signalService.on('SessionCreated');
  }
  get sessionSubjectChanged$() {
    return this.signalService.on('SessionSubjectChanged');
  }
  get sessionResized$() {
    return this.signalService.on('SessionResized');
  }
  get sessionSwitched$() {
    return this.signalService.on('SessionSwitched');
  }
  get sessionDeleted$() {
    return this.signalService.on('SessionDeleted');
  }

  private startSession(objectId: string, objectKind: ObjectKind, sessionId: string, date: string): Observable<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return from(this.signalService.invoke('StartSession', [orgId, objectId, objectKind, sessionId, date]));
  }

  private endSession(sessionId: string, date: string): Observable<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return from(this.signalService.invoke('EndSession', [orgId, sessionId, date]));
  }

  commitSession(sessionId: string, date: string): Promise<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return this.signalService.invoke('CommitTime', [orgId, sessionId, date]) ?? Promise.reject(new Error('not connection'));
  }

  commitSessionV2(sessionId: string, committedAt: string, pauseToken: string): Promise<any> {
    const organizationId = this.organizationService.organizationSelectedId;
    const data = {
      organizationId,
      sessionId,
      committedAt,
      pauseToken
    };
    return this.signalService.invokeV2('CommitTimeV2', data) ?? Promise.reject(new Error('not connection'));
  }

  сhangeObject(objectId: string, objectKind: ObjectKind): Observable<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return from(this.signalService.invoke('ChangeObject', [orgId, this.sessionId, objectId, objectKind]));
  }

  init(timeWorked: number, date: string) {
    this._isPlay$.next(false);
    this.sendToWorker('stop', date);
    this._timeWorkedState = timeWorked;
    this._duration$.next(timeWorked);
  }

  play(objectId: string, objectKind: ObjectKind, date: string): Observable<any> {
    if (!this.isPlayValue) {
      this._activeSessionStart = date;
      this.sendToWorker('stop', date);
      this.sessionId = crypto.randomUUID()
      this._isPlay$.next(true);
      this.sendToWorker('start', date, date);
      this._error$.next(null);
      return this.startSession(objectId, objectKind, this.sessionId, date).pipe(
        tap({
          next: (data) => {
            if (data?.statusCode === 409) {
              this._isPlay$.next(false);
              this.sendToWorker('stop', date);
              this._duration$.next(this._timeWorkedState);
              this._error$.next('409: conflict');
            }
          },
          error: (err) => {
            this._isPlay$.next(false);
            this.sendToWorker('stop', date);
            this._duration$.next(this._timeWorkedState);
            this._error$.next(err);
          }
        }),
      );
    } else return this.сhangeObject(objectId, objectKind);
  }

  stop(date: string): Observable<any> {
    this._isPlay$.next(false);
    this.sendToWorker('stop', date);
    return this.endSession(this.sessionId, date).pipe(
      tap({
        next: () => {
          this.sessionId = null;
          this._timeWorkedState = this._duration$.value;
        },
        error: (err) => this._error$.next(err)
      }),
      // retry({ count: 2, delay: 2000 })
    );
  }

  private commit() {
    const commitDate = DateTime.now().set({ millisecond: 0 }).toUTC().toISO({ suppressMilliseconds: true });
    this.commitSession(this.sessionId, commitDate);
  }

  private sendToWorker(event: 'start' | 'stop', committedAt: string, starttedAt?: string) {
    this.timeTrackingWorkerService.worker.postMessage({ event, committedAt, starttedAt });
  }

  run(sessionId: string, committedAt: string, starttedAt?: string) {
    this._activeSessionStart = starttedAt || committedAt;
    this.sendToWorker('stop', committedAt);
    this.sessionId = sessionId;
    this._isPlay$.next(true);
    const timeDifference = Math.floor(DateTime.now().set({ millisecond: 0 }).toUTC().diff(DateTime.fromISO(committedAt), 'seconds').seconds);
    this._duration$.next(timeDifference + this._timeWorkedState);
    // console.log('run', timeDifference, this._timeWorkedState)
    this.sendToWorker('start', committedAt, starttedAt);
  }

  updateDuration(timeWorked: number) {
    this._timeWorkedState = timeWorked;
    this._duration$.next(timeWorked);
  }

  private tick(duration: number) {
    if (duration > 0) {
      this._currentDate$.next(DateTime.now());
      const duarationAll = this._timeWorkedState + duration;
      this._duration$.next(duarationAll);
      // console.log(duration, duration % TimeTrackingService.DELTA === 0)
      if (duration % TimeTrackingSWService.DELTA === 0) {
        this.commit();
      }
    }
  }
}
