import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule()
export class IconsModule {
  /**
   * Constructor
   */
  constructor(
    private _domSanitizer: DomSanitizer,
    private _matIconRegistry: MatIconRegistry
  ) {
    // Register icon sets
    this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('mat_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('feather', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('heroicons_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('heroicons_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg'));

    this._matIconRegistry.addSvgIcon('tasks', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/tasks.svg'));
    this._matIconRegistry.addSvgIcon('projects', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/projects.svg'));
    this._matIconRegistry.addSvgIcon('screenshots', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/screenshots.svg'));
    this._matIconRegistry.addSvgIcon('xlsx', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/xlsx.svg'));
    this._matIconRegistry.addSvgIcon('csv', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/csv.svg'));
    this._matIconRegistry.addSvgIcon('ios', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/ios.svg'));
    this._matIconRegistry.addSvgIcon('windows', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/windows.svg'));
    this._matIconRegistry.addSvgIcon('logo-app', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/images/logo/logo.svg'));
    this._matIconRegistry.addSvgIcon('monitoring', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/monitoring.svg'));
    // this._matIconRegistry.addSvgIcon('groups', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/groups.svg'));
    // this._matIconRegistry.addSvgIcon('group_add', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/group_add.svg'));
    this._matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    this._matIconRegistry.addSvgIcon('file_save', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/file_save.svg'));
    this._matIconRegistry.addSvgIcon('pip', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/pip.svg'));
  }
}