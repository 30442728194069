<div class="flex justify-center items-center w-full my-4">
  <div class="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <div class="w-full sm:w-auto py-6 px-4 sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card">
        <div class="w-full max-w-120 sm:w-120 mx-auto sm:mx-0">
            <!-- Logo -->
            <div class="w-12 mx-auto">
                <img src="assets/images/logo/logo.svg">
            </div>
            <mat-progress-bar class="mt-10" *ngIf="isLoadingData$ | async" mode="indeterminate"></mat-progress-bar>
            <div >
              <!-- Title -->
              <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight text-center">{{title}}</div>
              <div [formGroup]="hubspotFormGroup" class="mt-8 text-md font-medium text-secondary text-center flex flex-col gap-4 justify-center">
                  <div formGroupName="fields" class="flex flex-col gap-2 justify-center">
                    <ng-container [ngTemplateOutlet]="field.templateRef" [ngTemplateOutletContext]="{ name:field.name, label:field.label }" *ngFor="let field of hubspotForm?.fields"></ng-container>
                    <ng-template #single_line_text let-name="name" let-label="label">
                      <mat-form-field [subscriptSizing]="'dynamic'">
                        <mat-label>{{label}}</mat-label>
                        <input [name]="name" matInput [formControlName]="name">
                        <mat-error>{{label}} is required!</mat-error>
                      </mat-form-field>
                    </ng-template>
                    <ng-template #multi_line_text let-name="name" let-label="label">
                      <mat-form-field [subscriptSizing]="'dynamic'">
                        <mat-label>{{label}}</mat-label>
                        <textarea [name]="name" matInput [formControlName]="name"></textarea>
                        <mat-error>{{label}} is required!</mat-error>
                      </mat-form-field>
                    </ng-template>
                    <ng-template #email let-name="name" let-label="label">
                      <mat-form-field [subscriptSizing]="'dynamic'">
                        <mat-label>{{label}}</mat-label>
                        <input [name]="name" matInput [formControlName]="name" type="email">
                        <mat-error *ngIf="hubspotFormGroup.get('fields').get(name).hasError('required')">{{label}} is required!</mat-error>
                        <mat-error *ngIf="hubspotFormGroup.get('fields').get(name).hasError('email')">Email is not valid!</mat-error>
                      </mat-form-field>
                    </ng-template>
                  </div>
                  <ng-container>
                    <div *ngIf="getFormConsents.length > 0" formArrayName="legalConsentOptions" class="flex flex-col gap-2 justify-center">
                      <ng-container [ngTemplateOutlet]="hubspotForm?.legalConsentOptions?.templateRef"
                        [ngTemplateOutletContext]="{
                          communicationText: hubspotForm?.legalConsentOptions?.communicationConsentText,
                          privacyText: hubspotForm?.legalConsentOptions?.privacyText,
                          processText: hubspotForm?.legalConsentOptions?.consentToProcessText
                        }">
                      </ng-container>
                      <ng-template #consent let-index="index" let-label="label">
                        <mat-checkbox color="primary" [formControlName]="index">{{label}}</mat-checkbox>
                      </ng-template>
                    </div>
                  </ng-container>
                  <button *ngIf="(isLoadingData$ | async) === false" mat-flat-button type="button" [color]="'primary'" (click)="submit()" [disabled]="hubspotFormGroup.invalid || (submitting$ | async)">
                    <span *ngIf="!(submitting$ | async);else spinner">Submit</span>
                  </button>
              </div>
            </div>
        </div>
    </div>
  </div>
</div>

<ng-template #spinner>
  <mat-spinner diameter="25"></mat-spinner>
</ng-template>

<ng-template #legitimate_interest></ng-template>
<ng-template #implicit_consent_to_process let-communicationText="communicationText" let-privacyText="privacyText" let-processText="processText">
  <div [innerHTML]="communicationText"></div>
  <ng-container
    *ngFor="let consent of hubspotForm.legalConsentOptions.communicationsCheckboxes; let i=index"
    [ngTemplateOutlet]="consentTempl"
    [ngTemplateOutletContext]="{
      index: i,
      label: consent.label
    }"
    >
  </ng-container>
  <div [innerHTML]="processText"></div>
  <div [innerHTML]="privacyText"></div>
</ng-template>
<ng-template #explicit_consent_to_process let-communicationText="communicationText" let-privacyText="privacyText" let-processText="processText">
  <div [innerHTML]="communicationText"></div>
  <ng-container
    *ngFor="let consent of hubspotForm.legalConsentOptions.communicationsCheckboxes; let i=index"
    [ngTemplateOutlet]="consentTempl"
    [ngTemplateOutletContext]="{
      index: i,
      label: consent.label
    }"
    >
  </ng-container>
  <div [innerHTML]="processText"></div>
  <div [innerHTML]="privacyText"></div>
</ng-template>
