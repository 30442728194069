import { ApplicationRef, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { IsNewVersionMsgAppComponent } from 'app/shared/components/is-new-version-msg-app/is-new-version-msg-app.component';
import { interval, first, switchMap, of, filter, Observable, concat, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateAppService {

  constructor(private readonly appRef: ApplicationRef,
    private readonly snackBar: MatSnackBar,
    private readonly updates: SwUpdate) {
    this.isLoadNewVersion$.pipe(
      filter(isNewVersion => isNewVersion),
    ).subscribe(() => this.snackBar.openFromComponent(IsNewVersionMsgAppComponent));
  }

  get isLoadNewVersion$(): Observable<boolean> {
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everyOneMinutes$ = interval(60 * 1000);
    const everyOneMinutesOnceAppIsStable$ = concat(appIsStable$, everyOneMinutes$);
    return everyOneMinutesOnceAppIsStable$.pipe(
      switchMap(() => of(this.updates.isEnabled)),
      filter(isEnabled => isEnabled),
      switchMap(() => {
        try {
          return from(this.updates.checkForUpdate());
        } catch (err) {
          console.error('Failed to check for updates:', err);
          return of(false)
        }
      })
    );
  }
}