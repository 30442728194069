// import { inject, Injectable } from '@angular/core';
// import { HubConnectionBuilder } from "@microsoft/signalr";
// import { environment } from 'environments/environment';
// import { EMPTY, ReplaySubject, Subject, catchError, combineLatest, delay, filter, from, lastValueFrom, map, merge, retry, shareReplay, skip, switchMap, take, takeUntil, tap, timer } from 'rxjs';
// import { OrganizationService } from './organization.service';
// import { AuthService } from '@auth0/auth0-angular';
// import { NetworkService } from './network.service';

// @Injectable({
//   providedIn: 'root'
// })
// export class SignalRService {
//   private readonly _reconnected$ = new Subject<void>();
//   private readonly _reconnect$ = new Subject<void>();
//   private hubConnection: signalR.HubConnection = this.createHubConnection();
//   private readonly _isConnect$ = new ReplaySubject<boolean>(1);
//   private readonly _error$ = new Subject<void>();
//   private readonly auth0Service = inject(AuthService);
//   private readonly organizationService = inject(OrganizationService);
//   private readonly onEvents$ = new Subject<{ methodName: string, args: Array<any> }>();
//   private readonly onEvents = new Set<string>();
//   private readonly networkService = inject(NetworkService);

//   constructor() {
//     this.connect().subscribe();
//   }

//   readonly isConnected$ = combineLatest({ isConnect: this._isConnect$, isOnline: this.networkService.isOnline$ }).pipe(
//     map(({ isConnect, isOnline }) => (isConnect === true && isOnline === true) ? true : false),
//     shareReplay({ bufferSize: 1, refCount: true })
//   );

//   readonly isReconnected$ = this._reconnected$.asObservable().pipe(skip(1));

//   readonly onlineChanged$ = this.on('UserOnlineChanged').pipe(
//     map(args => {
//       const [id, online] = args;
//       return { id, online };
//     })
//   );

//   private reconnect() {
//     this._reconnect$.next();
//   }

//   private createHubConnection() {
//     return new HubConnectionBuilder()//.configureLogging(LogLevel.Trace)
//       .withUrl(`${environment.hubConnection}`, {
//         accessTokenFactory: () => lastValueFrom(this.auth0Service.getAccessTokenSilently().pipe(take(1)))
//       }).withAutomaticReconnect([2, 4, 20]).build();
//   }

//   private connectionErrorHandler(err?: any) {
//     console.error('Error while starting connection: ' + err);
//     this._error$.next();
//     this._isConnect$.next(false);
//     this.reconnect();
//   }

//   public connect() {
//     return merge(
//       this._reconnect$.pipe(delay(15000), tap(() => console.log('rec'))),
//       this.auth0Service.isAuthenticated$.pipe(
//         filter(isAuthenticated => !!isAuthenticated)
//       )
//     ).pipe(
//       switchMap(() => {
//         return from(this.hubConnection.start()).pipe(
//           tap({
//             next: () => {
//               console.log('SignalR connected');
//               this._isConnect$.next(true);
//               this.hubConnection.onclose(() => {
//                 this.hubConnection = this.createHubConnection();
//                 console.log('SignalR close');
//                 this.connectionErrorHandler();
//               });
//             },
//             error: (err) => this.connectionErrorHandler(err)
//           }),
//           retry({ delay: () => this._reconnect$ })
//         )
//       }),
//       switchMap(() => from(this.hubConnection.invoke('Subscribe', this.organizationService.organizationSelectedId)).pipe(
//         takeUntil(this._error$),
//         tap({
//           next: () => {
//             console.log('Subscribe');
//             this._reconnected$.next();
//           },
//           error: (err) => console.error('Subscribe invoke error')
//         }),
//         retry({
//           count: 9,
//           delay: (_, count) => timer(Math.pow(2, count) * 1000)
//         }),
//         catchError(() => {
//           console.error('Subscribe error!');
//           return EMPTY;
//         })
//       ))
//     );
//   }

//   on(methodName: string) {
//     if (!this.onEvents.has(methodName)) {
//       this.onEvents.add(methodName);
//       this.hubConnection.on(methodName, (...args) => this.onEvents$.next({ methodName, args: [...args] }));
//     }
//     return this.isConnected$.pipe(
//       filter(isConnect => isConnect),
//       switchMap(() => this.onEvents$.asObservable()),
//       filter((event) => event.methodName === methodName),
//       map((event) => event.args),
//       shareReplay({ bufferSize: 1, refCount: true })
//     );
//   }

//   invoke(methodName: string, ...args: any[]) {
//     return this.hubConnection?.invoke(methodName, ...args);
//   }

//   invokeV2(methodName: string, args: any) {
//     return this.hubConnection?.invoke(methodName, args);
//   }
// }


import { Injectable } from '@angular/core';
import { HubConnectionBuilder } from "@microsoft/signalr";
import { environment } from 'environments/environment';
import { EMPTY, Subject, catchError, delay, filter, from, lastValueFrom, map, merge, retry, shareReplay, switchMap, take, takeUntil, tap, timer } from 'rxjs';
import { SignalRBase } from 'app/classes/signalR-base';

@Injectable({
  providedIn: 'root'
})
export class SignalRService extends SignalRBase {
  private readonly _reconnect$ = new Subject<void>();
  private hubConnection: signalR.HubConnection = this.createHubConnection();
  private readonly onEvents$ = new Subject<{ methodName: string, args: Array<any> }>();
  private readonly onEvents = new Set<string>();

  constructor() {
    super();
    this.connect().subscribe();
  }

  readonly onlineChanged$ = this.on('UserOnlineChanged').pipe(
    map(args => {
      const [id, online] = args;
      return { id, online };
    })
  );

  private reconnect() {
    this._reconnect$.next();
  }

  private createHubConnection() {
    return new HubConnectionBuilder()//.configureLogging(LogLevel.Trace)
      .withUrl(`${environment.hubConnection}`, {
        accessTokenFactory: () => lastValueFrom(this.auth0Service.getAccessTokenSilently().pipe(take(1))) //lastValueFrom(this.auth0Service.idTokenClaims$.pipe(map(token => token.__raw), take(1)))
      }).withAutomaticReconnect([2, 4, 20]).build();
  }

  protected connectionErrorHandler(err?: any) {
    super.connectionErrorHandler(err);
    this.reconnect();
  }

  public connect() {
    return merge(
      this._reconnect$.pipe(delay(15000), tap(() => console.log('rec'))),
      this.auth0Service.isAuthenticated$.pipe(
        filter(isAuthenticated => !!isAuthenticated)
      )
    ).pipe(
      switchMap(() => {
        return from(this.hubConnection.start()).pipe(
          tap({
            next: () => {
              console.log('SignalR connected');
              this._isConnect$.next(true);
              this.hubConnection.onclose(() => {
                this.hubConnection = this.createHubConnection();
                console.log('SignalR close');
                this.connectionErrorHandler();
              });
            },
            error: (err) => this.connectionErrorHandler(err)
          }),
          retry({ delay: () => this._reconnect$ })
        )
      }),
      switchMap(() => from(this.hubConnection.invoke('Subscribe', this.organizationService.organizationSelectedId)).pipe(
        takeUntil(this._error$),
        tap({
          next: () => {
            console.log('Subscribe');
            this._reconnected$.next();
          },
          error: (err) => console.error('Subscribe invoke error')
        }),
        retry({
          count: 9,
          delay: (_, count) => timer(Math.pow(2, count) * 1000)
        }),
        catchError(() => {
          console.error('Subscribe error!');
          return EMPTY;
        })
      ))
    );
  }

  on(methodName: string) {
    if (!this.onEvents.has(methodName)) {
      this.onEvents.add(methodName);
      this.hubConnection.on(methodName, (...args) => this.onEvents$.next({ methodName, args: [...args] }));
    }
    return this.isConnected$.pipe(
      filter(isConnect => isConnect),
      switchMap(() => this.onEvents$.asObservable()),
      filter((event) => event.methodName === methodName),
      map((event) => event.args),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  invoke(methodName: string, ...args: any[]) {
    try {
      return this.hubConnection?.invoke(methodName, ...args).catch((err) => {
        console.error(err, `${methodName} args: `, ...args)
      });
    } catch (err) {
      console.error(err, `${methodName} args: `, ...args)
    }
  }

  invokeV2(methodName: string, args: any) {
    try {
      return this.hubConnection?.invoke(methodName, args).catch((err) => {
        console.error(err, `${methodName} args: `, args)
      });;
    } catch (err) {
      console.error(err, `${methodName} args: `, args)
    }
  }
}