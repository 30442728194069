export function secondsToTime(seconds: number): string {
  if (seconds >= 60) {
    const isHourDuration = Math.floor(seconds / 3600);
    const h = isHourDuration.toString();
    const m = Math.floor(seconds % 3600 / 60).toString();
    return (isHourDuration >= 1) ? `${h}h ${m}m` : `${m} m`;
  } else return seconds === 0 ? '0' : `< 1m`;
}

export function secondsToTime2(seconds: number): string {
  if(seconds > 0) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor(((seconds / 60) % 60));
    const s = Math.floor((seconds % 60));
    return `${(h <= 9) ? '0' + h : h}:${(m <= 9) ? '0' + m : m}:${(s <= 9) ? '0' + s : s}`;
  } else return '-';
}