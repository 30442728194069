<div class="mx-3">
  <div class="flex items-center gap-4 cursor-pointer px-3 py-2 rounded-md user-label" [matMenuTriggerFor]="menu" *ngIf="user$ | async as user">
    <div class="relative">
      <div [matTooltip]="user.displayName"
        class="flex items-center justify-center w-9 h-9 min-w-9 rounded-full overflow-hidden">
        <img [src]="user.picture" class="w-full h-full object-contain">
      </div>
      <div class="absolute top-0 -right-1 h-3 w-3 rounded-full bg-gray-300"
        [ngClass]="{'bg-green-400': isConnectedSignalR$ | async}"
        [matTooltip]="(isConnectedSignalR$ | async) ? 'Online' : 'Offline'"></div>
    </div>
    <div class="flex flex-col text-slate-200 user-label-text">
      <span>{{user.name}}</span>
      <span class="text-sm text-secondary">{{user.email}}</span>
    </div>
    <mat-menu [xPosition]="'before'" #menu="matMenu">
      <div class="px-6 py-2">
        <span class="flex flex-col leading-none">
          <span>Signed in as</span>
          <span class="mt-1.5 text-md font-semibold text-secondary">{{user?.email}}</span>
        </span>
      </div>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="{{'/settings/general'}}">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
      </button>
      <button mat-menu-item routerLink="{{'/settings/general'}}">
        <mat-icon [svgIcon]="'mat_outline:settings'"></mat-icon>
        <span>Setting</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="logout()">
        <mat-icon [svgIcon]="'logout'"></mat-icon>
        <span>Sign Out</span>
      </button>
    </mat-menu>
  </div>
</div>