export interface Ticket {
  id: string,
  title: string,
  description: string,
  status: string
  assignee: {
    id: string,
    displayName: string,
    role: string
  },
  category: {
    id: string,
    name: string
  },
  createdAt: string,
  updatedAt: string,
  rank: number,
  project: { id: string, name: string, isTracking?: boolean },
  isTracking?: boolean,
  isOwner?: boolean
}

export enum TicketStatus {
  Open = 'Open',
  Closed = 'Closed',
}

export interface TicketDetails {
  ticket: Ticket
}