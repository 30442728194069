import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { convertToLocalDate } from 'app/utils/convert-to-local-date';
import { ProjectRole } from 'app/models/roles';
import { Organization } from 'app/models/organization/organization';
import { OrganizationsList } from 'app/models/organization/organizations-list';
import { AppCategoriesList, AppCategory } from 'app/models/category/category';

export interface User {
  Id?: number,
  UserGUID?: string,
  UserId: number,
  FirstName: string,
  LastName: string,
  Email: string,
  Notes?: null | string,
  IsDelete?: boolean,
  TimeStamp?: string,
  createdDate: number,
  organizationsIds: Array<number>,
  rolesIds: Array<number>,
  createdAt: string,
  Organizations?: {
    Id: number,
    IsDelete: boolean,
    Name: string
    UserRoles: ProjectRole[]
  }[]
}

export interface Project {
  id: number,
  title: string,
  description: string,
  orgId: number,
  status: number,
  isDelete: boolean,
  timeStamp: string,
  createdDate: number,
  createdAt: string
}

@Injectable({
  providedIn: 'root'
})
export class AdminAppService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig) { }

  static serializeTimeStamp(timeStamp: string | number): string {
    return new Date(new Date(convertToLocalDate(timeStamp)).setHours(0, 0)).toJSON();
  }

  getOrganizationsList(): Observable<Organization[]> {
    return this.httpClient.get<OrganizationsList>(`${this.appConfig.baseUrl}/organizations`).pipe(
      map((organizationsList) => organizationsList.list),
      map(organizationsList => organizationsList.sort((v1, v2) => v1.name.localeCompare(v2.name)))
    );
  }

  getUsersList(): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this.appConfig.oldBaseUrl}/api/superadmin/users`).pipe(
      map(usersList => usersList.map(user => ({ ...user, createdAt: AdminAppService.serializeTimeStamp(user.TimeStamp) }))),
      map(usersList => usersList.sort((v1, v2) => v1.FirstName.localeCompare(v2.FirstName)))
    );
  }

  getProjectsList(): Observable<Project[]> {
    return this.httpClient.get<Project[]>(`${this.appConfig.oldBaseUrl}/api/superadmin/projects`).pipe(
      map(usersList => usersList.map(user => ({ ...user, createdAt: AdminAppService.serializeTimeStamp(user.timeStamp) })))
    );
  }

  categoriesList(type: string = 'job'): Observable<Array<AppCategory>> {
    return this.httpClient.get<AppCategoriesList>(`${this.appConfig.baseUrl}/categories/${type}`).pipe(
      map(catigoriesList => catigoriesList.list)
    );
  }

  addCategory(name: string, type: string = 'job'): Observable<any> {
    return this.httpClient.post<any>(`${this.appConfig.baseUrl}/categories/${type}`, { name });
  }

  updateCategory(id: string, name: string, type: string = 'job'): Observable<any> {
    return this.httpClient.patch<any>(`${this.appConfig.baseUrl}/categories/${type}/${id}`, { name });
  }

  deleteCategory(id: string, type: string = 'job'): Observable<any> {
    return this.httpClient.delete<any>(`${this.appConfig.baseUrl}/categories/${type}/${id}`, {});
  }

}