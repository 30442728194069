<div class="flex items-center justify-center w-10 h-10 rounded-full text-lg uppercase font-semibold bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-200 cursor-pointer" 
[matMenuTriggerFor]="menu"
[matTooltip]="selectedOrganization"
>
  {{organization}}
</div>

<mat-menu #menu="matMenu" class="max-h-120">
  <button mat-menu-item (click)="addOrganization()">
    <mat-icon [svgIcon]="'heroicons_solid:plus'"></mat-icon>
    <span>New</span>
  </button>
  <mat-divider></mat-divider>
  <div class="flex items-center" *ngFor="let organization of organizations$ | async">
    <button [disabled]="organization.id === selectedOrganizationId" mat-menu-item
      (click)="selectOrganization(organization?.id)">
      <span>{{organization?.name}}</span>
    </button>
    <mat-icon class="ml-auto mr-2 text-green-600" *ngIf="(organization.id === selectedOrganizationId)"
      svgIcon="heroicons_outline:check"></mat-icon>
  </div>
</mat-menu>