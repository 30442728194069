<div class="flex flex-col gap-4">
  <div class="flex items-center justify-between mb-2">
    <div class="text-2xl">Create a Project</div>
    <button mat-icon-button matDialogClose><mat-icon svgIcon="mat_solid:close"></mat-icon></button>
  </div>
  <div [formGroup]="projectForm" class="flex flex-col w-60 sm:w-72 md:w-96 overflow-hidden">
    <div class="flex flex-col gap-4">
      <mat-form-field class="flex-auto gt-xs:pr-3 fuse-mat-dense" [subscriptSizing]="'dynamic'">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" cdkFocusInitial>
        <mat-error>Project is required!</mat-error>
      </mat-form-field>
      <mat-form-field class="flex-auto gt-xs:pl-3 fuse-mat-dense" [subscriptSizing]="'dynamic'">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
      <div>
        <label for="colorInput" class="font-medium">Color</label>
        <div
          class="mt-1.5 h-12 flex flex-col justify-center border rounded-md border-gray-300 dark:border-gray-500 px-4">
          <input [cpOutputFormat]="'hex'" id="colorInput" cpPosition="top" [(colorPicker)]="color" matInput
            formControlName="color" [style.background]="projectForm.get('color').value" readonly />
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-end mt-4">
    <div class="flex flex-col flex-1 gap-2 sm:flex-row sm:flex-none">
      <button class="" mat-button mat-dialog-close>
        Cancel
      </button>
      <button class="" mat-flat-button [color]="'primary'" [disabled]="formInValid || (submitting$ | async)"
        (click)="submit()">
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="ml-2 mr-1">Add Project</span>
      </button>
    </div>
  </div>
</div>