import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { Team } from 'app/models/organization/team/team';
import { Teams } from 'app/models/organization/team/teams';
import { OrganizationService } from 'app/services/organization.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly organizationService: OrganizationService) { }

  get() {
    return this.httpClient.get<Teams>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/groups`).pipe(
      map(teams => teams.list),
      map(teams => teams.sort((t1, t2) => t1.name.localeCompare(t2.name))),
      map(teams => {
        return teams.map(team => ({
          ...team,
          teamLeads: team.members?.filter(member => member?.isTeamLead === true)?.map(member => {
            const displayName = member.displayName?.split(' ');
            const charFirst = displayName[0] || '';
            const charLast = displayName[1] || '';
            return ({ ...member, label: `${charFirst?.charAt(0)}${charLast?.charAt(0)}` })
          }) || [],
          members: team.members?.filter(member => member?.isTeamLead === false)?.map(member => {
            const displayName = member.displayName?.split(' ');
            const charFirst = displayName[0] || '';
            const charLast = displayName[1] || '';
            return ({ ...member, label: `${charFirst?.charAt(0)}${charLast?.charAt(0)}` })
          }) || [],
        }))
      }),
    );
  }

  update(id: string, team: Partial<Team>) {
    return this.httpClient.patch(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/groups/${id}`, team);
  }

  add(team: Team) {
    return this.httpClient.post(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/groups`, team);
  }

  remove(id: string) {
    return this.httpClient.delete(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/groups/${id}`);
  }
}
