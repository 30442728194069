export function convertToLocalDate(date: string | number): number {
  return (typeof date === 'string') ? new Date(`${date}Z`).setSeconds(0, 0) : date;
}

export function convertToLocalDateWithOutZ(date: string | number): number {
  return (typeof date === 'string') ? new Date(`${date}`).setSeconds(0, 0) : date;
}

export function convertToLocalDateWithSec(date: string | number): number {
  return (typeof date === 'string') ? +new Date(`${date}`) : date;
}