import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'tm-is-new-version-msg-app',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './is-new-version-msg-app.component.html',
  styleUrls: ['./is-new-version-msg-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IsNewVersionMsgAppComponent {
  readonly snackBarRef = inject(MatSnackBarRef);

  update() {
    console.dir('reload');
    this.snackBarRef.dismiss();
    document.location.reload();
  }
}
