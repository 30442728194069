import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateLocale',
  standalone: true
})
export class DateLocalePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) { }

  transform(value: Date | string): unknown {
    try {
      const datePipe: DatePipe = new DatePipe(this.locale);
      return datePipe.transform(value);
    } catch (error) {
      console.log('Language not supported!')
      const datePipe: DatePipe = new DatePipe('en-GB');
      return datePipe.transform(value);
    }

  }

}
