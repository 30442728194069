import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { secondsToTime2 } from 'app/utils/seconds-to-time';
import { TimeTrackingWorkerService } from './time-tracking-worker.service';

@Injectable({
  providedIn: 'root'
})
export class TimeTitleStrategyService extends TitleStrategy {
  subscription: Subscription | null = null;
  readonly duration$ = new Subject<number>();
  private isPlay = false;
  constructor(
    private readonly title: Title,
    private readonly timeTrackingWorkerService: TimeTrackingWorkerService,
  ) {
    super();
    this.timeTrackingWorkerService.worker.addEventListener('message', (message: MessageEvent) => {
      const { durationActive, isPlay } = message.data;
      this.isPlay = isPlay;
      this.duration$.next(durationActive);
    });
  }

  updateTitle(snapshot: RouterStateSnapshot): void {
    this.subscription?.unsubscribe();
    const title = this.buildTitle(snapshot) || '';
    if (!this.isPlay) {
      this.title.setTitle(title);
    }
    this.subscription = this.duration$.subscribe((v: number) => {
      if (this.isPlay) {
        this.title.setTitle(`▶ ${secondsToTime2(v)} ${title}`);
      } else {
        this.title.setTitle(title);
      }
    });
  }
}
