interface Industry {
  id: number,
  name: string,
  description: string
}
export const INDUSTRYLIST: Array<Industry> = [
  {
    id: 1,
    name: 'Information Technology(IT)',
    description: 'Encompasses various technology services, including software development, networking, and cybersecurity'
  },
  {
    id: 2,
    name: 'Software Development',
    description: 'Involves creating and maintaining software applications for diverse purposes, from business to entertainment.'
  },
  {
    id: 3,
    name: 'Web Development and Design',
    description: 'Focuses on building websites, web applications, and user interfaces for online platforms.'
  },
  {
    id: 4,
    name: 'Digital Marketing',
    description: 'Utilizes technology for online advertising, social media marketing, SEO, and data-driven strategies.'
  },
  {
    id: 5,
    name: 'E-commerce',
    description: 'Revolves around online buying and selling, utilizing websites, digital platforms, and electronic payments.'
  },
  {
    id: 6,
    name: 'Media and Content Creation',
    description: 'Utilizes computers for content creation, graphic design, video editing, animation, and multimedia.'
  },
  {
    id: 7,
    name: 'Gaming and Entertainment',
    description: 'Develops computer games, interactive media, virtual reality experiences, and multimedia content.'
  },
  {
    id: 8,
    name: 'Finance and Fintech',
    description: 'Integrates technology in financial services, such as online banking, digital payments, and investment.'
  },
  {
    id: 9,
    name: 'Cybersecurity',
    description: 'Addresses protection against cyber threats and unauthorized access through computer-based security.'
  },
  {
    id: 10,
    name: 'Cloud Computing',
    description: 'Provides remote access to computing resources and services over the internet for scalability and flexibility.'
  },
  {
    id: 11,
    name: 'Healthcare Technology',
    description: 'Applies technology to medical services, electronic health records, telemedicine, and health management.'
  },
  {
    id: 12,
    name: 'Telecommunications',
    description: 'Involves communication via electronic devices, encompassing mobile networks, internet services, and more.'
  },
  {
    id: 13,
    name: 'Education Technology (EdTech)',
    description: 'Uses technology to enhance education with online courses, virtual classrooms, and interactive learning.'
  },
  {
    id: 14,
    name: 'Artificial Intelligence (AI) and Machine Learning',
    description: 'Develops algorithms and models for simulating human intelligence and learning from data.'
  },
  {
    id: 15,
    name: 'Automotive and Transportation',
    description: 'Integrates computers into vehicles, navigation systems, autonomous driving, and transportation management.'
  },
  {
    id: 16,
    name: 'Energy and Utilities',
    description: 'Utilizes technology for managing energy production, distribution, and consumption efficiently.'
  },
  {
    id: 17,
    name: 'Retail',
    description: 'Relies on computers for inventory management, online sales, customer management, and supply chain optimization.'
  },
  {
    id: 18,
    name: 'Logistics and Supply Chain',
    description: 'Uses technology to manage the movement of goods, inventory, and supply chain operations.'
  },
  {
    id: 19,
    name: 'Aerospace and Aviation',
    description: 'Involves computer-based systems for aircraft design, navigation, communication, and flight simulations.'
  },
  {
    id: 0,
    name: 'Other',
    description: ''
  },
];