import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/core/material/material.module';
import { AppCategory } from 'app/models/category/category';
import { AdminAppService } from 'app/services/admin-app.service';
import { NgForAugmentedDirective } from 'app/shared/directives/ng-for-augmented.directive';
import { takeUntil, Subject, startWith, switchMap, merge, debounceTime, distinctUntilChanged, filter, map, combineLatest, catchError, of, mapTo, tap } from 'rxjs';

interface CategoryForm {
  type: FormControl<'job' | 'ticket'>,
  name: FormControl<string>,
}

@Component({
  selector: '',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss'],
  standalone: true,
  imports: [MaterialModule, FormsModule, ReactiveFormsModule, CommonModule, NgForAugmentedDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCategoryComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly refreshCategoriesList$ = new Subject<void>();
  private readonly updateCategory$ = new Subject<AppCategory>();
  private readonly loading$ = new Subject<null>();

  readonly categoryForm = this.fb.group<CategoryForm>({
    type: new FormControl('job', [Validators.required]),
    name: new FormControl('', [Validators.required]),
  });

  get typeFC(): FormControl<'job' | 'ticket'> {
    return this.categoryForm.get('type') as FormControl<'job' | 'ticket'>;
  }

  get newCategoryFC(): FormControl<string> {
    return this.categoryForm.get('name') as FormControl<string>;
  }

  get isCreateNewCategory(): boolean {
    return this.newCategoryFC.value.trim() !== ''
  }

  readonly categories$ = merge(combineLatest([
    merge(this.refreshCategoriesList$, this.typeFC.valueChanges).pipe(
      startWith(this.typeFC.value),
      tap(() => this.loading$.next(null)),
      switchMap(() => this.adminAppService.categoriesList(this.typeFC.value))),
    this.newCategoryFC.valueChanges.pipe(startWith(''), distinctUntilChanged(), debounceTime(150))
  ]).pipe(
    map(([categories, name]) => categories.filter(category => category.name.includes(name || ''))),
    catchError(() => of([]))
  ), this.loading$);

  constructor(
    private readonly adminAppService: AdminAppService,
    private readonly fb: FormBuilder,
    private readonly chr: ChangeDetectorRef
  ) { }

  addCategory(): void {
    if (this.isCreateNewCategory) {
      const type = this.typeFC.value
      const name = this.categoryForm.get('name').value;
      this.adminAppService.addCategory(name, type).subscribe(() => {
        this.refreshCategoriesList$.next();
        this.newCategoryFC.reset('');
      });
    }
  }

  deleteCategory(id: string): void {
    const type = this.typeFC.value;
    this.adminAppService.deleteCategory(id, type).subscribe(() => this.refreshCategoriesList$.next());
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  ngOnInit(): void {
    this.updateCategory$.pipe(
      debounceTime(300),
      distinctUntilChanged((category, category2) => category.name === category2.name),
      filter(category => category.name.trim() !== ''),
      takeUntil(this.destroy$),
      switchMap((category: AppCategory) => {
        const type = this.typeFC.value;
        return this.adminAppService.updateCategory(category.id, category.name, type)
      })
    ).subscribe(() => {
      this.refreshCategoriesList$.next();
      this.newCategoryFC.reset('');
    })

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
