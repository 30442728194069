<mat-form-field class="fuse-mat-dense w-full" [subscriptSizing]="'dynamic'">
  <mat-label>Country</mat-label>
  <mat-icon class="icon-size-5" [svgIcon]="'mat_outline:edit_location'" matPrefix></mat-icon>
  <mat-select placeholder="Select Country" formControlName="countryCode">
    <mat-option>
      <ngx-mat-select-search [formControl]="countriesFilterCtrl" placeholderLabel="Find Country..." noEntriesFoundLabel="'no matching counties found'"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let countryCode of countryListFilter$ | async" [value]="countryCode.value">{{countryCode.label}}</mat-option>
  </mat-select>
  <mat-error>Country field is required</mat-error>
</mat-form-field>