import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import { CommunicationsCheckboxes, HubspotField, HubspotForm, HubspotGroupsField, HubspotService, LegalConsentOptions } from '../../services/hubspot.service';
import { BehaviorSubject, Subject, takeUntil } from "rxjs";
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseAlertComponent } from '@fuse/components/alert';

@Component({
  selector: 'tm-report-issue-form',
  templateUrl: './report-issue-form.component.html',
  styleUrls: ['./report-issue-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportIssueFormComponent implements OnDestroy, OnInit {
  @ViewChild('single_line_text', { static: true }) private readonly singleLineText: TemplateRef<any>;
  @ViewChild('multi_line_text', { static: true }) private readonly multiLineText: TemplateRef<any>;
  @ViewChild('email', { static: true }) email: TemplateRef<any>;

  @ViewChild('legitimate_interest', { static: true }) private readonly legitimateInterest: TemplateRef<any>;
  @ViewChild('implicit_consent_to_process', { static: true }) private readonly implicitConsentToProcess: TemplateRef<any>;
  @ViewChild('explicit_consent_to_process', { static: true }) private readonly explicitConsentToProcess: TemplateRef<any>;

  @ViewChild('consent') consentTempl: TemplateRef<any>;
  isLoadingData$ = new BehaviorSubject<boolean>(true);
  submitting$ = new BehaviorSubject<boolean>(false);
  submited$ = new BehaviorSubject<boolean>(false);
  title = '';
  static formId = '57cbb219-a4fa-4403-81e6-b43b3d936ba4';
  /*
  '9a67aec5-1118-4406-b754-b8b0fe37df10'
  '57d8b404-a4b2-46d5-a21e-146650fce7cc'
  '57cbb219-a4fa-4403-81e6-b43b3d936ba4'
  */
  hubspotForm: { fields: Array<HubspotField>, legalConsentOptions: LegalConsentOptions } = { fields: [], legalConsentOptions: { type: "none" } };
  hubspotFormGroup: FormGroup = this.fb.group({ fields: this.fb.group({}), legalConsentOptions: this.fb.array([]) });
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly hubspotService: HubspotService,
    private readonly chr: ChangeDetectorRef,
    private readonly fb: FormBuilder,
    private readonly snackBar: MatSnackBar) { }

  private addValidators(constrol: any): Validators | [] {
    const validators = [];
    if (constrol.required) {
      validators.push(Validators.required);
    }
    if (constrol.fieldType === 'email') {
      validators.push(Validators.email);
    }
    return validators;
  }

  private addField(field: HubspotField): void {
    const validators = this.addValidators(field);
    this.getFormFields.addControl(field.name, this.fb.control('', validators));
  }

  private addConsent(consent: CommunicationsCheckboxes): void {
    const validators = this.addValidators(consent);
    const control = this.fb.control(false, validators);
    this.getFormConsents.push(control);
  }

  get getFormFields(): FormGroup {
    return this.hubspotFormGroup.get('fields') as FormGroup;
  }

  get getFormConsents(): FormArray {
    return this.hubspotFormGroup.get('legalConsentOptions') as FormArray;
  }
  private getConsentValue(index: number): boolean {
    return this.getFormConsents.controls[index].value;
  }

  private createForm(hubspotForm: HubspotForm): void {
    const fieldsForm = 'fields';
    this.hubspotForm['legalConsentOptions'] = hubspotForm.legalConsentOptions;
    const legalConsentOptionsType = hubspotForm.legalConsentOptions.type;
    const legalConsentOptions = this.hubspotForm['legalConsentOptions'];
    const fields = [...hubspotForm.fieldGroups.map(group => group[fieldsForm])].flat().map(group => {
      switch (group.fieldType) {
        case 'single_line_text':
          group['templateRef'] = this.singleLineText;
          break;
        case 'multi_line_text':
          group['templateRef'] = this.multiLineText;
          break;
        case 'email':
          group['templateRef'] = this.email;
          break;
      }
      return group;
    });
    this.hubspotForm[fieldsForm] = fields;

    fields.forEach(field => {
      this.addField(field);
    });

    switch (legalConsentOptionsType) {
      case 'legitimate_interest':
        legalConsentOptions['templateRef'] = this.legitimateInterest;
        break;
      case 'implicit_consent_to_process':
        legalConsentOptions['templateRef'] = this.implicitConsentToProcess;
        hubspotForm.legalConsentOptions.communicationsCheckboxes.forEach(consent => {
          this.addConsent(consent);
        });
        break;
      case 'explicit_consent_to_process':
        legalConsentOptions['templateRef'] = this.explicitConsentToProcess;
        hubspotForm.legalConsentOptions.communicationsCheckboxes.forEach(consent => {
          this.addConsent(consent);
        });
        break;
    }
  }

  submit(): void {
    this.submitting$.next(true);
    const fields = this.hubspotForm.fields.map((value) => ({
      objectTypeId: value.objectTypeId,
      name: value.name,
      value: this.hubspotFormGroup.get('fields').get(`${value.name}`).value
    }));

    let data = {
      fields,
    };

    if (this.hubspotForm.legalConsentOptions.type !== 'none') {
      const legalConsentOptions = {
        consent: {
          consentToProcess: Object.hasOwn(this.hubspotForm.legalConsentOptions, 'consentToProcessText')
        }
      };
      if (Object.hasOwn(this.hubspotForm.legalConsentOptions, 'communicationsCheckboxes')) {
        const communications = (this.hubspotForm.legalConsentOptions['communicationsCheckboxes'] as Array<CommunicationsCheckboxes>).map((value, i) => ({
          subscriptionTypeId: value.subscriptionTypeId,
          text: value.label,
          value: this.getConsentValue(i)
        }));
        legalConsentOptions.consent['communications'] = communications;
      }
      if (Object.hasOwn(this.hubspotForm.legalConsentOptions, 'privacyText')) {
        legalConsentOptions.consent['text'] = this.hubspotForm.legalConsentOptions['privacyText'];
      }
      data = {
        ...data,
        ...legalConsentOptions
      }
    }

    this.hubspotService.submitForm(ReportIssueFormComponent.formId, data).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (data: { inlineMessage: string }) => {
        this.submitting$.next(false);
        this.submited$.next(true);
        this.hubspotFormGroup.reset();
        this.snackBar.open(data.inlineMessage, '', { duration: 3000 });
      },
      error: (err) => {
        this.submitting$.next(false);
        this.submited$.next(false);
        console.error(err);
        //this.snackBar.open(err.inlineMessage, '', { duration: 3000 });
      }
    });
  }

  ngOnInit(): void {
    this.hubspotService.getForm(ReportIssueFormComponent.formId).pipe(
      takeUntil(this.destroy$)
    ).subscribe((data) => {
      this.title = data.name;
      this.createForm(data);
      this.isLoadingData$.next(false);
      this.chr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}