<div class="inset-0 absolute flex justify-center items-center">
  <div class="flex flex-col flex-auto items-center sm:justify-center min-w-0">
    <!-- <ng-container [ngTemplateOutlet]="(isAuth$ | async) ? spinner : loginFailed"></ng-container> -->
    <ng-container [ngTemplateOutlet]="loginFailed"></ng-container>
  </div>
</div>

<ng-template #loginFailed>
  <div class="w-full sm:w-auto py-8 px-4 sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card">
    <div class="w-full max-w-120 sm:w-100 mx-auto sm:mx-0">
        <!-- Logo -->
        <div class="w-12 mx-auto">
            <img src="assets/images/logo/logo.svg">
        </div>
        <!-- Title -->
        <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight text-center">Login failed!</div>
        <!-- Form footer -->
        <div class="mt-8 text-md font-medium text-secondary text-center flex gap-4 justify-center">
            <button mat-stroked-button [color]="'primary'" class="w-full" (click)="tryAgain()">
              Try again
            </button>
              <button mat-flat-button [color]="'primary'" class="w-full" (click)="logout()">
              Logout
            </button>
        </div>
    </div>
  </div>
</ng-template>

<ng-template #spinner>
  <mat-spinner [diameter]="80"></mat-spinner>
</ng-template>