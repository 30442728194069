export interface Project {
  id: string,
  name: string,
  description: string,
  status: string,
  createdAt: string,
  color?: number | string,
  users?: Array<
    {
      id: string,
      displayName: string,
      role: ProjectRoles
    }
  >,
  ticketCount?: number,
  isTracking?: boolean,
}

export enum ProjectStatus {
  Normal = 'Normal',
  Archived = 'Archived',
}


export enum ProjectRoles {
  Manager = 'Manager',
  Member = 'Member',
}