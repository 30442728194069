import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationService } from 'app/services/organization.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { filter, shareReplay } from 'rxjs';
import { OrganizationAddComponent } from 'app/modules/organization/modules/settings/components/organization-add/organization-add.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'tm-organization-change',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, MatMenuModule, MatIconModule, MatDividerModule],
  templateUrl: './organization-change.component.html',
  styleUrls: ['./organization-change.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationChangeComponent {
  readonly organizations$ = this.organizationService.organizations$.pipe(shareReplay(1));
  constructor(private readonly organizationService: OrganizationService, private readonly dialog: MatDialog,) { }

  get organization() {
    const name = this.organizationService.organizationSelected.name.split(' ');
    const first = name[0] || '';
    const last = name[1] || '';
    return `${first?.charAt(0)}${last?.charAt(0)}`
  }

  selectedOrganizationId = this.organizationService.organizationSelectedId;
  selectedOrganization = this.organizationService.organizationSelected.name

  selectOrganization(organizationId: string) {
    this.organizationService.select(organizationId);
  }

  addOrganization(): void {
    const dialogRef = this.dialog.open(OrganizationAddComponent);
    dialogRef.afterClosed().pipe(
      filter(isUpdate => isUpdate),
    ).subscribe((id) => {
      this.organizationService.select(id);
    });
  }
}
