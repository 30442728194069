<div class="min-w-64 w-full">
  <mat-form-field #inputOrigin subscriptSizing="dynamic" class="fuse-mat-rounded fuse-mat-dense w-full" (click)="openPanel()">
    <mat-date-range-input [formGroup]="rangeFG">
      <input matStartDate formControlName="start" placeholder="Start date" readonly>
      <input matEndDate formControlName="end" placeholder="End date" readonly>
    </mat-date-range-input>
    <button mat-icon-button matSuffix>
      <mat-icon svgIcon="mat_outline:date_range"></mat-icon>
    </button>
  </mat-form-field>
</div>

<ng-template #calendarPanel>
  <div class="flex bg-card items-center w-full flex-col md:flex-row shadow-lg">
    <mat-button-toggle-group name="ranges" class="flex flex-col p-2" (change)="selectPreset($event)">
      <mat-button-toggle [checked]="presetSelected === preset.id" [value]="preset"
        *ngFor="let preset of presets">{{preset.name}}</mat-button-toggle>
    </mat-button-toggle-group>
    <mat-calendar class="flex-auto min-w-64" [selected]="selected" [startAt]="selected.start"
      (selectedChange)="selectedRange($event)" [maxDate]="maxDate" #calendar></mat-calendar>
  </div>
</ng-template>