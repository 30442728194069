import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, map, ReplaySubject, tap, switchMap, Subject, repeat, shareReplay, defer, merge } from 'rxjs';
import { AppConfig, APP_CONFIG } from 'app/app-config.module';
import { CurrentProject } from 'app/models/current-project';
import { OrganizationService } from 'app/services/organization.service';
import { ProjectsList } from 'app/models/project/projects';
import { Project, ProjectRoles, ProjectStatus } from 'app/models/project/project';
import { Ticket, TicketDetails, TicketStatus } from 'app/models/ticket/ticket';
import { Tickets } from 'app/models/ticket/tickets';
import { ReportsService } from './report.service';
import { UserService } from './user.service';
import { SignalRService } from './signal-r.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private readonly _projects$ = new ReplaySubject<Array<Project>>(1);
  private readonly _refresh$ = new Subject<void>();

  static sortProjects(pr1: CurrentProject, pr2: CurrentProject): number {
    return pr1.PrjTitle.localeCompare(pr2.PrjTitle);
  }

  get projects$() {
    return this._projects$.asObservable();
  }
  get userProjects$() {
    return this.userService.user$.pipe(
      map(user => user.id),
      switchMap(userId => {
        return this.projects$.pipe(
          map(projects => projects.filter(project => project.users.findIndex(user => user.id === userId) > -1)),
          // repeat({ delay: () => this._refresh$ })
        )
      }))
  }

  get projectCreated$() {
    return this.signalService.on('ProjectCreated');
    // return this.signalService.isConnected$.pipe(
    //   switchMap(() => defer(() => new Observable<void>((observer) => {
    //     this.signalService.hubConnection.on('ProjectCreated', () => {
    //       observer.next();
    //     });
    //   }))),
    //   shareReplay({ bufferSize: 1, refCount: true })
    // );
  }

  get projectNameChanged$() {
    return this.signalService.on('ProjectNameChanged');
    // return this.signalService.isConnected$.pipe(
    //   switchMap(() => defer(() => new Observable<void>((observer) => {
    //     this.signalService.hubConnection.on('ProjectNameChanged', () => {
    //       observer.next();
    //     });
    //   }))),
    //   shareReplay({ bufferSize: 1, refCount: true })
    // );
  }

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private readonly organizationService: OrganizationService,
    private readonly reportService: ReportsService,
    private readonly userService: UserService,
    private readonly signalService: SignalRService,) {
    this.get().subscribe();
    merge(this.projectCreated$, this.projectNameChanged$).subscribe(() => this.refresh());
  }

  refresh() {
    this._refresh$.next();
  }

  getCurrentProjects(): Observable<Array<CurrentProject>> {
    return this.httpClient.get<Array<CurrentProject>>(`${this.appConfig.oldBaseUrl}/api/admin/projects/selected`).pipe(
      map(projects => projects.filter(project => project.PrjId !== -1))
    );
  }

  get(): Observable<Array<Project>> {
    // return this.userService.user$.pipe(
    //   map(user => user.id),
    //   switchMap(userId => {
    //     const params = new HttpParams().append('userId', userId);
    return this.httpClient.get<ProjectsList>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/projects`).pipe(
      map(projectsList => projectsList.list),
      map(projects => projects.map(project => ({ ...project, color: project?.color ? `#${(project.color).toString(16)}` : '' }))),
      tap(projects => this._projects$.next(projects)),
      repeat({ delay: () => this._refresh$ })
    )
    // }));
  }

  addProjects(project: { name: string, description: string, color?: number, users?: Array<{ id: string, role: ProjectRoles }>, groups?: Array<string> }): Observable<{ project: Project }> {
    return this.httpClient.post<{ project: Project }>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/projects`, project);
  }

  assignUser(projectId: string, userId: string, role: ProjectRoles) {
    return this.httpClient.put(`${this.appConfig.baseUrl}/projects/${projectId}/users/${userId}`, { role });
  }

  deleteUser(projectId: string, userId: string) {
    return this.httpClient.delete(`${this.appConfig.baseUrl}/projects/${projectId}/users/${userId}`);
  }

  getTasksProject(projectId: string): Observable<Array<Ticket>> {
    let params = new HttpParams();
    params = params.append('status', 'Closed');
    params = params.append('status', 'Open');
    return this.httpClient.get<Tickets>(`${this.appConfig.baseUrl}/projects/${projectId}/tickets`, { params }).pipe(
      map((tasks: Tickets) => tasks.list)
    );
  }

  getTask(id: string): Observable<Ticket> {
    return this.httpClient.get<TicketDetails>(`${this.appConfig.baseUrl}/tickets/${id}`).pipe(
      map(ticketDetails => ticketDetails.ticket)
    );
  }
  // getTasks(ids: Array<string>): Observable<Array<Tickets>> {
  //   let params = new HttpParams();
  //   ids.forEach(id => params = params.append('ids', id))
  //   return this.httpClient.get<Array<Tickets>>(`${this.appConfig.baseUrl}/tickets`, { params }).pipe(
  //     // map(ticketDetails => ticketDetails.ticket)
  //   );
  // }

  removeTicket(taskId: string): Observable<any> {
    return this.httpClient.put(`${this.appConfig.oldBaseUrl}/api/tasks/${taskId}/9`, {});
  }

  changeStatusTicket(id: string, status: TicketStatus): Observable<TicketDetails> {
    return this.httpClient.patch<TicketDetails>(`${this.appConfig.baseUrl}/tickets/${id}`, { status });
  }

  createTicket(projectId: string, data: any): Observable<any> {
    return this.httpClient.post(`${this.appConfig.baseUrl}/projects/${projectId}/tickets`, data);
  }

  editTicket(id: string, ticket: any): Observable<TicketDetails> {
    return this.httpClient.patch<TicketDetails>(`${this.appConfig.baseUrl}/tickets/${id}`, ticket);
  }

  asigneeTask(userId: string, ticketId: string,): Observable<any> {
    return this.httpClient.put(`${this.appConfig.baseUrl}/tickets/${ticketId}/assignee`, { userId });
  }

  editProject(projectId: string, name: string, description: string, color: number): Observable<any> {
    return this.httpClient.patch(`${this.appConfig.baseUrl}/projects/${projectId}`, { name, description, color });
  }

  projectToArchive(projectId: string): Observable<any> {
    return this.httpClient.patch(`${this.appConfig.baseUrl}/projects/${projectId}`, { status: ProjectStatus.Archived });
  }

  restoreProject(projectId: string): Observable<any> {
    return this.httpClient.patch(`${this.appConfig.baseUrl}/projects/${projectId}`, { status: ProjectStatus.Normal });
  }

  getMembers(projectId: string): Observable<Array<{ id: string, displayName: string, role: ProjectRoles, ticketCount?: number }>> {
    return this.httpClient.get<{ list: Array<{ id: string, displayName: string, role: ProjectRoles, ticketCount?: number }> }>(`${this.appConfig.baseUrl}/projects/${projectId}/users`).pipe(
      map(members => members.list)
    );
  }

  ticketRankTransition(taskId: string, data: { insertBefore: string, insertAfter: string }): Observable<any> {
    return this.httpClient.put<{ insertBefore: string, insertAfter: string }>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/tickets/${taskId}/transition`, data);
  }

  projectStats(params: HttpParams) {
    return this.reportService.getTimeSheets(params);
  }

  addUsersAndTeams(projectId: string, data: { users?: Array<{ id: string, role: ProjectRoles }>, groups?: Array<string> }): Observable<any> {
    return this.httpClient.post(`${this.appConfig.baseUrl}/projects/${projectId}/users`, data);
  }
}