import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditCategoryComponent } from 'app/modules/admin-app/components/edit-category/edit-category.component';

@Component({
  selector: 'tm-admin-app',
  templateUrl: './admin-app.component.html',
  styleUrls: ['./admin-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminAppComponent {
  constructor(public dialog: MatDialog) {}
  addCategory(): void {
    this.dialog.open(EditCategoryComponent, { autoFocus: false }).addPanelClass(['max-w-full', 'w-full', 'h-full', 'sm:h-fit' ,'sm:w-60', 'lg:w-80']);
  }
}
