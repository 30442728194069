<div class="relative">
  <div class="relative rounded-full" [ngClass]="{'tm-animate': isPlay$ | async}">
    <div class="relative cursor-pointer rounded-full z-10" [matTooltip]="tooltip$ | async"
      routerLink="./user/work-history">
      <div class="px-3 rounded-full flex items-center border border-primary-700 py-1 leading-4 text-sm md:text-base gap-1"
        [ngClass]="{'border-0 bg-primary-700 text-white': isPlay$ | async}" [matTooltip]="errors$ | async" matRipple>
        <div class="">Today:</div>
        <ng-container *ngIf="(state$ | async); else spinnerTmpl">
          <div class="w-15 md:w-16 flex flex-col font-semibold">
            <div class="text-right">{{duration$ | async | secondsToTimePipe}}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="absolute -top-[2px] -right-[5px] h-2 w-2 rounded-full bg-gray-400"
    [ngClass]="{'bg-green-600 dark:bg-green-700': isConnectedSignalR$ | async}"
    [matTooltip]="(isConnectedSignalR$ | async) ? 'Online' : 'Offline'"></div>
</div>

<ng-template #spinnerTmpl>
  <div class="flex flex-auto justify-center">
    <mat-spinner diameter="17"></mat-spinner>
  </div>
</ng-template>