import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from 'app/services/user.service';
import { combineLatest, map, tap } from 'rxjs';
import { SignalRService } from 'app/services/signal-r.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '@auth0/auth0-angular';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'tm-user-label',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, MatMenuModule, MatIconModule, RouterLink, MatDividerModule],
  templateUrl: './user-label.component.html',
  styleUrls: ['./user-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserLabelComponent {
  constructor(
    private readonly userService: UserService,
    private readonly signalRService: SignalRService,
    private readonly auth0Service: AuthService,
    private readonly router: Router,
  ) { }

  readonly isConnectedSignalR$ = this.signalRService.isConnected$;

  private readonly userProfile$ = this.userService.user$.pipe(
    map(user => {
      const displayName = user.displayName?.split(' ');
      const firstName = displayName[0] || '';
      const lastName = displayName[1] || '';
      return ({
        ...user,
        shortName: `${firstName?.charAt(0)}${lastName?.charAt(0)}`
      });
    })
  );

  readonly user$ = combineLatest({ userProfile: this.userProfile$, authUser: this.auth0Service.user$ }).pipe(
    map(({ authUser, userProfile }) => ({ ...userProfile, ...authUser }))
  );

  logout() {
    this.auth0Service.logout();
  }

}
