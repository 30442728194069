<fuse-card class="w-full h-full flex-col p-6">
  <div class="flex items-start justify-between">
    <div class="text-lg font-medium">{{title}}</div>
    <div class="ml-2">
      <div class="h-6 min-h-6 px-2 rounded-full bg-hover" mat-button>
        <span class="font-medium text-sm text-secondary whitespace-nowrap">last 7 days</span>
      </div>
    </div>
  </div>
    <ng-container [ngTemplateOutlet]="(chartOptions$ | async) ? chart : skeleton" [ngTemplateOutletContext]="{chartOptions: chartOptions$ | async}"></ng-container>
</fuse-card>

<ng-template #skeleton>
  <div class="mt-8 flex flex-col gap-4">
    <div class="flex justify-center">
      <ngx-skeleton-loader [appearance]="'circle'" *ngIf="skeletonConfigCircle$ | async as skeletonConfigCircle"
        [theme]="skeletonConfigCircle.theme" [animation]="skeletonConfigCircle.animation">
      </ngx-skeleton-loader>
    </div>
    <ngx-skeleton-loader class="mt-8" count="5" *ngIf="skeletonConfig$ | async as skeletonConfig" [theme]="skeletonConfig.theme"
      [animation]="skeletonConfig.animation">
    </ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #chart let-chartOptions="chartOptions">
  <div class="flex flex-col md:flex-row lg:flex-col">
    <div class="mt-4 h-full flex items-center justify-center">
      <apx-chart class="w-full h-64"
        [chart]="chartOptions?.chart"
        [labels]="chartOptions?.labels"
        [series]="chartOptions?.series"
        [colors]="chartOptions?.colors"
        [tooltip]="chartOptions?.tooltip"
        [legend]="chartOptions?.legend"
        [noData]="chartOptions?.noData">
      </apx-chart>
    </div>
    <div class="mt-8 flex-1" *ngIf="chartOptions?.labels?.length > 0">
      <div class="-my-3 divide-y">
        <ng-container *ngFor="let dataset of chartOptions?.labels; let i = index">
          <div class="flex items-center justify-between py-3.5 gap-2">
            <div class="flex items-center gap-2 min-w-5" [matTooltip]="chartOptions?.labels[i]">
              <div class="w-3 h-3 rounded-full min-w-3" [style.backgroundColor]="chartOptions?.colors[i]"></div>
              <div class="min-w-5">
                <div class="truncate">{{chartOptions?.labels[i]}}</div>
                <div class="truncate text-secondary text-md">{{chartOptions?.projectsList[i]}}</div>
              </div>
            </div>
            <div class="text-lg font-light">{{chartOptions?.series[i] | secondsToTime}}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>