<div class="flex flex-col flex-auto min-w-0 p-4 gap-2 w-full">
  <div class="text-3xl font-semibold">Time & activity report</div>
  <div class="flex flex-col items-center gap-2 sm:flex-row sm:ml-auto">
      <tm-date-range-picker class="w-full" (selectRange)="selectedRange$.next($event)"></tm-date-range-picker>
      <tm-user-selection class="w-full" (selectionChange)="selectedUsers$.next($event)"></tm-user-selection>
  </div>
  <div class="flex flex-col" *ngIf="(dataReport$ | async)?.data?.length > 0 || (loading$ | async); else noDataFound">
      <div class="flex items-center my-2 justify-between">
        <button mat-flat-button [matMenuTriggerFor]="groupingMenu" class="text-secondary">
            <mat-icon svgIcon="heroicons_outline:menu-alt-2"></mat-icon>
            <span class="ml-3">Group by:</span>
            <span class="ml-2 text-primary-500">{{selectedGroupField}}</span>
        </button>
        <mat-menu #groupingMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="grouping$.next('UserId')">
                <mat-icon svgIcon="heroicons_outline:user"></mat-icon>
                <span>User</span>
            </button>
            <button mat-menu-item (click)="grouping$.next('PrjId')">
                <mat-icon svgIcon="heroicons_outline:briefcase"></mat-icon>
                <span>Project</span>
            </button>
        </mat-menu>
        <button (click)="exportToExcel()" mat-flat-button [color]="'primary'">
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:save'"></mat-icon>
            <span class="ml-2">Export</span>
        </button>
      </div>
      <mat-progress-bar mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>
      <mat-table mat-table [dataSource]="(dataReport$ | async)" matSort class="mat-elevation-z0">
          <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{selectedGroupField || 'User'}} </mat-header-cell>
              <mat-cell *matCellDef="let element">
                  {{element.name}}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef class="font-semibold"> Total </mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="totalTracked">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Time Worked </mat-header-cell>
              <mat-cell *matCellDef="let element">
                  <div class="flex flex-col whitespace-nowrap">
                      <div>{{element.totalTracked | secondsToTime}}</div>
                      <div [matTooltip]="'Automatic'" class="text-secondary text-sm leading-none" *ngIf="element.autoTracked > 0">A: {{element.autoTracked | secondsToTime}}</div>
                      <div [matTooltip]="'Manual'" class="text-secondary text-sm leading-none" *ngIf="element.manualTracked > 0">M: {{element.manualTracked | secondsToTime}}</div>
                  </div>
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                  <div class="flex flex-col font-semibold whitespace-nowrap" *ngIf="totalTime$ | async as totalTime">
                      <div>{{totalTime.all | secondsToTime}}</div>
                      <div [matTooltip]="'Automatic'" class="text-secondary text-sm leading-none" *ngIf="totalTime.auto > 0">A: {{totalTime.auto | secondsToTime}}</div>
                      <div [matTooltip]="'Manual'" class="text-secondary text-sm leading-none" *ngIf="totalTime.manual > 0">M: {{totalTime.manual | secondsToTime}}</div>
                  </div>
              </mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="activity">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Activity </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.activity}}</mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="chart">
              <mat-header-cell *matHeaderCellDef><div class="w-full text-center">Time Worked Chart</div></mat-header-cell>
              <mat-cell *matCellDef="let element" class="w-full">
                  <div class="w-full py-1">
                      <apx-chart *ngIf="selectedGroupField !== 'Date'"
                          [series]="element.chartLine.series"
                          [labels]="element.chartLine.labels"
                          [chart]="element.chartLine.chart"
                          [tooltip]="element.chartLine.tooltip"
                          [xaxis]="element.chartLine.xaxis"
                          [stroke]="element.chartLine.stroke"
                      ></apx-chart>
                  </div>
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
          <mat-row *matRowDef="let element; columns: columnsToDisplay;"></mat-row>
          <mat-footer-row *matFooterRowDef="columnsToDisplay; sticky: true"></mat-footer-row>
      </mat-table>
      <mat-divider></mat-divider>
      <mat-paginator [pageSizeOptions]="[8, 10, 25, 50]" aria-label="Select page"></mat-paginator>
  </div>
  <ng-template #noDataFound>
    <fuse-card class="p-4 justify-center rounded-none">
        <div class="text-secondary text-2xl text-center my-4">No data found!</div>
    </fuse-card>
  </ng-template>
</div>