<div class="flex flex-col min-h-72 h-full">
  <ng-container [ngTemplateOutlet]="chartData ? chart : skeleton"></ng-container>
</div>

<ng-template #chart>
  <div class="flex justify-center" *ngIf="!chartData.tooltip.enabled">
    <div class="text-xl font-semibold text-secondary mt-4">No data found!</div>
  </div>
  <apx-chart
    class="flex-auto w-full h-full"
    [series]="chartData.series"
    [chart]="chartData.chart"
    [dataLabels]="chartData.dataLabels"
    [plotOptions]="chartData.plotOptions"
    [yaxis]="chartData.yaxis"
    [legend]="chartData.legend"
    [fill]="chartData.fill"
    [stroke]="chartData.stroke"
    [tooltip]="chartData.tooltip"
    [xaxis]="chartData.xaxis"
    [grid]="chartData.grid"
  ></apx-chart>
</ng-template>

<ng-template #skeleton>
  <ngx-skeleton-loader class="mt-4" [animation]="(scheme$ | async) === 'light' ? 'progress': 'progress-dark'" [theme]="{
      'border-radius': '5px',
      height: '350px',
      'background-color': (scheme$ | async) === 'light' ? '' : '#313C4E'
    }">
  </ngx-skeleton-loader>
</ng-template>