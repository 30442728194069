import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[tmInputFocus]',
  standalone: true
})
export class InputFocusDirective implements AfterViewInit {
  constructor(private host: ElementRef) { }

  ngAfterViewInit() {
    this.host.nativeElement.focus();
  }
}
