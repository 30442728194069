import { inject, InjectionToken } from "@angular/core";
import { LocalStorageService } from "./local-storage.service";
import { CountryLanguageService } from "./country-language.service";

export const S_LOCALE = new InjectionToken<string>('sLocale',
  {
    factory: () => {
      const countryLanguageService = inject(CountryLanguageService);
      const localStorageService = inject(LocalStorageService);
      const locale = localStorageService.getItem('locale') || '';
      const [lang, code] = locale?.split('-');
      if (countryLanguageService.countryLanguage.languageCodeExists(lang) && countryLanguageService.countryLanguage.countryCodeExists(code)) {
        return locale;
      }
      return '';
    }
  }
);