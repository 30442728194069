import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { secondsToTime } from 'app/utils/seconds-to-time';
import { ApexOptions, NgApexchartsModule } from 'ng-apexcharts';
import { Observable, Subject, filter, map, shareReplay } from 'rxjs';
import { NgxSkeletonLoaderConfig, NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonConfigService } from 'app/services/skeleton-config.service';
import { CommonModule } from '@angular/common';
import { FuseCardModule } from '@fuse/components/card';
import { SecondsToTimePipe } from 'app/shared/pipes/seconds-to-time.pipe';


@Component({
  selector: 'tm-top-five-chart',
  templateUrl: './top-five-chart.component.html',
  styleUrls: ['./top-five-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FuseCardModule, NgxSkeletonLoaderModule, SecondsToTimePipe, NgApexchartsModule]
})
export class TopFiveChartComponent {
  @Input() sparkline = true;
  @Input() title = '';
  @Input() set data(value: Array<{ name: string, parentName: string, timeWorked: number }> | null) {
    if (value !== null) {
      this._chartData$.next(value);
    }
  }

  private readonly _chartData$ = new Subject<any>();

  constructor(private readonly skeletonConfigService: SkeletonConfigService) { }

  get skeletonConfigCircle$(): Observable<NgxSkeletonLoaderConfig> {
    return this.skeletonConfigService.skeletonConfig$.pipe(
      map(config => ({
        ...config,
        theme: {
          ...config.theme,
          'border-radius': '50%',
          width: '210px',
          height: '210px'
        }
      }))
    );
  }

  get skeletonConfig$(): Observable<NgxSkeletonLoaderConfig> {
    return this.skeletonConfigService.skeletonConfig$.pipe(
      map(config => ({
        ...config,
        theme: {
          ...config.theme,
          'border-radius': '5px',
          height: '50px'
        }
      }))
    );
  }

  readonly chartOptions$ = this._chartData$.pipe(
    filter(data => !!data),
    map((data) => this.prepareChart(data)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  )

  private prepareChart(data: Array<{ name: string, parentName: string, timeWorked: number }>): Partial<ApexOptions | unknown> {
    let isData = data?.length > 0;
    const labels = data.map(item => `${item.name}`);
    const projectsList = data.map(item => `${item.parentName}`);
    const series = data.map(item => item.timeWorked);
    // const colors = data.map((item, idx) => getColor(item.name, idx));
    return {
      series,
      chart: {
        animations: {
          enabled: true,
          speed: 400,
          animateGradually: {
            enabled: false
          },
        },
        fontFamily: 'inherit',
        foreColor: 'inherit',
        height: '100%',
        type: 'donut',
        sparkline: {
          enabled: this.sparkline
        }
      },
      dataLabels: {
        enabled: false,
      },
      noData: {
        text: 'No data found!',
        align: 'center',
        verticalAlign: 'middle',
        offsetY: -15,
        style: {
          color: 'var(--fuse-text-secondary)'
        }
      },
      legend: {
        show: false
      },
      colors: ['#7D02EB', '#A300D6', '#5653FE', '#2983FF', '#00B1F2'],
      labels: isData ? labels : [],
      projectsList,
      tooltip: {
        enabled: isData,
        fillSeriesColor: false,
        theme: 'dark',
        y: {
          formatter: (v) => secondsToTime(v)
        }
      }
    };
  }
}