import { NgModule, InjectionToken } from '@angular/core';
import { environment } from 'environments/environment';

export interface AppConfig {
  baseUrl: string;
  oldBaseUrl: string
}

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');

@NgModule({
  providers: [
    {
      provide: APP_CONFIG,
      useValue: environment.apiConfig,
    },
  ],
})
export class AppConfigModule { }