import { ChangeDetectionStrategy, Component, Input, ChangeDetectorRef } from "@angular/core";
import { FuseConfigService } from "@fuse/services/config";
import { AppConfig, Scheme } from "app/core/config/app.config";
import { Subject, takeUntil } from 'rxjs';
import { DataChart } from "app/models/dashboards/data-chart";
import { secondsToTime } from "app/utils/seconds-to-time";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexGrid,
  ApexOptions
} from "ng-apexcharts";
import { BehaviorSubject } from "rxjs";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  grid: ApexGrid;
};



@Component({
  selector: 'tm-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarChartComponent {
  scheme$ = new BehaviorSubject<Scheme>('auto');
  private readonly destroy$ = new Subject<void>();
  chartData: ApexOptions;
  @Input() set data(value: DataChart<any>) {
    if (value.isLoadData) {
      this.prepareChart(value.data);
    }
  }

  constructor(
    private readonly chr: ChangeDetectorRef,
    private fuseConfigService: FuseConfigService) { }

  prepareChart(value: Array<any>) {
    const isData = value.length > 0;
    let series = [
      {
        name: "",
        data: [3600, 5400, 3600, 3600, 5400, 3600, 3600, 5400, 3600]
      }
    ];
    let categories = ['', '', '', '', '', '', '', '', ''];
    let columnWidth = "30%";
    let colors = ['var(--fuse-text-secondary)'];
    // if (isData) {
    //   series = value.series;
    //   categories = categories;
    //   columnWidth = `${((value.categories.length || 1)) + 30}%`;
    //   colors = ['#008FFB'];
    // }


    this.chartData = {
      series,
      chart: {
        type: "bar",
        height: '100%',
        toolbar: {
          show: false
        },
        fontFamily: 'inherit',
        foreColor: 'inherit',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth,
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 4,
        colors: ["transparent"]
      },
      xaxis: {
        categories,
        labels: {
          style: {
            colors: 'var(--fuse-text-secondary)'
          }
        }
      },
      yaxis: {
        labels: {
          formatter: (v) => secondsToTime(v),
          style: {
            colors: 'var(--fuse-text-secondary)'
          }
        }
      },
      fill: {
        opacity: 1,
        colors
      },
      tooltip: {
        enabled: isData,
        theme: 'dark',
        y: {
          formatter: function (val) {
            return secondsToTime(val);
          }
        }
      },
      grid: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 5
        },
      }
    };
  }

  ngOnInit(): void {
    this.fuseConfigService.config$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((config: AppConfig) => this.scheme$.next(config.scheme));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.scheme$.complete();
  }
}