import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToTimePipe',
  standalone: true
})
export class SecondsToTimePipe implements PipeTransform {

  transform(seconds: number): string {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor(((seconds / 60) % 60));
    const s = Math.floor((seconds % 60));
    return `${(h <= 9) ? '0' + h : h}:${(m <= 9) ? '0' + m : m}:${(s <= 9) ? '0' + s : s}`;
  }
}