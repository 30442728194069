import { Injectable } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';
import { NgxSkeletonLoaderConfig } from 'ngx-skeleton-loader';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SkeletonConfigService {

  constructor(private readonly fuseConfigService: FuseConfigService) { }

  get skeletonConfig$(): Observable<NgxSkeletonLoaderConfig> {
    return this.fuseConfigService.config$.pipe(
      map(config => {
        const scheme = config.scheme;
        return ({
          ariaLabel: '',
          loadingText: '',
          count: 1,
          appearance: '',
          animation: (scheme) === 'light' ? 'progress' : 'progress-dark',
          theme: {
            extendsFromRoot: true,
            'background-color': (scheme) === 'light' ? '#CCD0D4' : '#313C4E',
            'border-radius': '5px',
            'margin-bottom': '0',
            height: '45px'
          },
        })
      })
    );
  }
}
