<button mat-icon-button (click)="setScheme('dark')" *ngIf="config.scheme === 'light'; else dark">
    <mat-icon
    class="icon-size-5"
    [svgIcon]="'heroicons_solid:moon'"></mat-icon>
</button>
<ng-template #dark>
    <button mat-icon-button (click)="setScheme('light')">
        <mat-icon
        class="icon-size-5"
        [svgIcon]="'heroicons_solid:sun'"></mat-icon>
    </button>
</ng-template>