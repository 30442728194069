import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeTrackingWorkerService {
  worker: Worker;
  constructor() {
    this.initSW();
  }

  private initSW() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('/src/app/time-tracking.worker', import.meta.url), { name: 'TimeTracking', type: 'module' });
      this.worker.onerror = (error) => {
        console.error('An error occurred in the worker:', error);
      };
    }
  }
}
