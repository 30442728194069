<div class="relative rounded-full min-w-50">
  <div class="flex flex-col rounded-full border border-primary-700 pl-[5px] pr-2 py-1 w-full">
    <div class="flex items-center gap-1 leading-4">
       <ng-container *ngIf="(state$ | async); else spinnerTmpl">
          <div class="min-w-8">
            <ng-container [ngTemplateOutlet]="(isPlay$ | async) ? stopBtnTmpl : playBtnTmpl"></ng-container>
          </div>
          <div class="flex flex-col flex-auto min-w-20" *ngIf="selectedObject$ | async as selectedObject">
            <span class="text-secondary uppercase text-sm ml-1.5">{{selectedObject?.objectKind === 'Ticket' ? 'Task' : selectedObject?.objectKind}}</span>
              <div class="flex-auto min-w-0">
                <div class="flex gap-1 items-center flex-auto">
                  <div matRipple
                    class="cursor-pointer hover:bg-slate-200 dark:hover:bg-slate-600 rounded-full pl-1.5 pr-0.5 flex items-center min-w-0"
                    [matMenuTriggerFor]="menu" [matTooltip]="selectedObject?.title" #menuTr="matMenuTrigger">
                    <span class="truncate text-sm md:text-md">{{selectedObject?.title}}</span>
                    <div class="h-3 min-w-6 overflow-hidden flex items-center ml-1">
                      <mat-icon svgIcon="more_horiz" class="text-gray-700 dark:text-white icon-size-6"></mat-icon>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="hidden sm:flex flex-col md:min-w-18" *ngIf="isPlayValue">
            <span class="text-secondary uppercase text-sm text-center">Session</span>
              <div class="font-bold text-sm min-w-14 md:text-[14px] text-center">
              {{durationActive$ | async | secondsToTimePipe}}
            </div>
          </div>
          <div class="hidden sm:block border border-gray-400 self-stretch my-0.5"></div>
          <div class="flex flex-col md:min-w-18 cursor-pointer" [routerLink]="'user/work-history'">
            <span class="text-secondary uppercase text-sm text-center">Today</span>
              <div class="font-bold text-sm min-w-14 md:text-[14px] text-center">
              {{duration$ | async | secondsToTimePipe}}
            </div>
          </div>
       </ng-container>
    </div>
  </div>
    <!-- <div class="absolute top-[3px] -right-1 h-2 w-2 rounded-full bg-gray-400"
    [ngClass]="{'bg-green-600 dark:bg-green-700': isConnectedSignalR$ | async}"
    [matTooltip]="(isConnectedSignalR$ | async) ? 'Online' : 'Offline'"></div> -->
</div>
<div class="text-xs text-warn-500" *ngIf="errors$ | async as errors">
  {{errors}}
</div>

<ng-template #playBtnTmpl>
  <button class="w-8 h-8 rounded-full flex justify-center items-center border-2 border-primary-700 shadow-md" mat-ripple
    (click)="play()">
    <mat-icon class="icon-size-6 text-primary-700" svgIcon="mat_solid:play_arrow"
      [matTooltip]="'Start tracking'"></mat-icon>
  </button>
</ng-template>

<ng-template #stopBtnTmpl>
  <button class="w-8 h-8 rounded-full flex justify-center items-center border-2 border-primary-700 shadow-md" mat-ripple
    (click)="stop()">
    <mat-icon class="icon-size-6 text-primary-700" svgIcon="mat_solid:stop" [matTooltip]="'Stop tracking'"></mat-icon>
  </button>
</ng-template>

<ng-template #spinnerTmpl>
  <div class="flex flex-auto justify-center">
    <mat-spinner [diameter]="20"></mat-spinner>
  </div>
</ng-template>

<mat-menu #menu="matMenu" class="min-w-40">
  <button *ngIf="projectsList.length > 0" mat-menu-item [matMenuTriggerFor]="projectsMenu">Projects</button>
  <button *ngIf="ticketsList.length > 0" mat-menu-item [matMenuTriggerFor]="ticketsMenu">Tasks</button>
  <button mat-menu-item (click)="createTicket()">
    <mat-icon svgIcon="heroicons_outline:plus"></mat-icon>
    <span>Add Task</span>
  </button>
  <button mat-menu-item (click)="sessionNewAdd()">
    <mat-icon svgIcon="heroicons_outline:plus"></mat-icon>
    <span>Log Manual Time</span>
  </button>
  <button mat-menu-item (click)="resize()" *ngIf="!(isPip$ | async)">
    <mat-icon svgIcon="pip" class="icon-size-5"></mat-icon>
    <span>Minimaze</span>
  </button>
</mat-menu>

<mat-menu #projectsMenu="matMenu" class="max-h-120">
  <button mat-menu-item *ngFor="let project of projectsList" (click)="changeObject(project)"
    [disabled]="project.id === (selectedObject$ | async)?.id">{{project.title}}</button>
</mat-menu>

<mat-menu #ticketsMenu="matMenu" class="max-h-120">
  <button mat-menu-item *ngFor="let ticket of ticketsList" (click)="changeObject(ticket)"
    [disabled]="ticket.id === (selectedObject$ | async)?.id">{{ticket.title}}</button>
</mat-menu>


<div class="h-0 w-0 overflow-hidden" id="widgetPiP-container">
  <div id="widgetPiP" class="min-w-80 py-1.5 px-2 text-white leading-5 flex gap-2 items-center">
    <div class="min-w-8">
      <ng-container [ngTemplateOutlet]="(isPlay$ | async) ? stopBtnTmpl2 : playBtnTmpl2"></ng-container>
    </div>
    <div class="flex-auto min-w-0">
      <div class="flex-auto min-w-0 flex items-center justify-between"
        *ngIf="selectedObject$ | async as selectedObject">
        <div class="truncate text-lg">{{selectedObject?.title}}<span *ngIf="isPlayValue">:</span></div>
        <div *ngIf="isPlayValue" class="font-bold text-lg">
          {{durationActive$ | async | secondsToTimePipe}}
        </div>
      </div>
      <div class="flex-auto min-w-0 flex items-center justify-between">
        <div *ngIf="isPlayValue" class="text-sm leading-5 flex items-center gap-1">
          <mat-icon class="text-white icon-size-5" svgIcon="mat_outline:access_time"></mat-icon>
          <div class="flex items-center h-3 leading-3"><span>{{startedAt | date:'HH:mm'}}</span><span>-</span><span>{{currentDate | date:'HH:mm'}}</span></div>
        </div>
        <div class="flex items-center">
          <div class="truncate text-lg font-semibold">Today:</div>
          <div class="font-bold text-xl w-20 text-end">
            {{duration$ | async | secondsToTimePipe}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #playBtnTmpl2>
  <button class="w-8 h-8 rounded-full flex justify-center items-center border-2 border-white shadow-md" mat-ripple
    (click)="play()">
    <mat-icon class="icon-size-5 text-white" svgIcon="mat_solid:play_arrow" [matTooltip]="'Start tracking'"></mat-icon>
  </button>
</ng-template>

<ng-template #stopBtnTmpl2>
  <button class="w-8 h-8 rounded-full flex justify-center items-center border-2 border-white shadow-md" mat-ripple
    (click)="stop()">
    <mat-icon class="icon-size-5 text-white" svgIcon="mat_solid:stop" [matTooltip]="'Stop tracking'"></mat-icon>
  </button>
</ng-template>